<template>
  <ion-app>
    <div :class="{ 'player-active': paddingBottom }">
      <ion-router-outlet />
    </div>

    <PlayerOverlayMobile v-if="isMobileScreen" />
    <PlayerOverlay v-else />

    <AppNotification />
  </ion-app>
</template>

<script setup>
import { computed, onMounted, watch } from 'vue';
import { IonApp, IonRouterOutlet, useBackButton } from '@ionic/vue';
import debounce from 'lodash/debounce';
import { useRouter, useRoute } from 'vue-router';
import { useStore } from 'vuex';
import { SplashScreen } from '@capacitor/splash-screen';
import { App } from '@capacitor/app';
import { defineAsyncComponent } from 'vue';

// Composables
import useNetworkConnectionAlerts from "@/composables/useNetworkConnectionAlerts";
import usePushNotifications from "@/composables/usePushNotifications";
import useDeepLink from "@/composables/useDeepLink";
import useVersionCheker from "@/composables/useVersionCheker";
import useFirebase8 from "@/composables/useFirebase8";
import useAppStorage from "@/composables/useAppStorage";
import useDeviceScreen from "@/composables/useDeviceScreen";

// Plugins and helpers
import { changeLocale } from '@/plugins/i18n';
import i18n from '@/plugins/i18n';
import auth from '@/plugins/auth';
import { checkPlatform } from "@/helpers/functions";

// Components
import AppNotification from "@/components/app/AppNotification";

// Async Components
const PlayerOverlay = defineAsyncComponent(() => import("@/components/player/PlayerOverlay"));
const PlayerOverlayMobile = defineAsyncComponent(() => import("@/components/player/PlayerOverlayMobile"));

// Setup store and route
const store = useStore();
const router = useRouter();
const route = useRoute();

// Device screen
const { isMobileScreen } = useDeviceScreen();

// App storage migration
const { migrateFromCapacitor2 } = useAppStorage();
migrateFromCapacitor2();

// Firebase setup
const { initFirebase, initDB: fireDB } = useFirebase8();
initFirebase();

// Platform check
checkPlatform().then((platform) => {
  store.commit('app/setAppPlatform', platform);
});

// get app config
store.dispatch('app/getAppConfig');
store.commit('app/setAppState', true);
store.dispatch('player/loadRecentlyPlayedProgressStorage');

// Network alerts
const { initAlerts } = useNetworkConnectionAlerts();
initAlerts();

// Push notifications
const { initNotifications, checkNotificationsGranted } = usePushNotifications();
const logged = store.getters['authentication/isLogged'];
if (logged) {
  initNotifications();
}

// Version check
const { checkCurrentVersion } = useVersionCheker();
checkCurrentVersion(1000);

// Deep linking
const { subscribeLinkOpen } = useDeepLink();
subscribeLinkOpen();

// Computed
const paddingBottom = computed(() => store.state.player.playerActive);

// Router back handler
const debouncedRouterBack = debounce(() => {
  const playerActive = store.state.player.playerActive;
  const playerCollapsed = store.state.player.playerCollapsed;

  if (playerActive && !playerCollapsed) {
    store.commit('player/setPlayerCollapse', true);
    return;
  }

  router.back();
}, 250, { trailing: true, leading: true });

// Watch route for player availability
watch(route, () => {
  if (!route?.meta?.player) {
    console.log('[Alert]: player is not available in this route');
    store.dispatch('player/closePlayer');
  }
},  { immediate: true, deep: true });

// Back button handler
useBackButton(1, debouncedRouterBack);

// App state listener
App.addListener('appStateChange', ({ isActive }) => {
  store.commit('app/setAppState', isActive);

  if (isActive) {
    if (store.getters['authentication/isLogged']) {
      checkNotificationsGranted();
    }
    fireDB().goOnline();
  }
});

// Mounted hook
onMounted(() => {
  if (logged && auth.user()?.language) {
    changeLocale(i18n, auth.user().language);
    store.commit('profile/changeLanguage', auth.user().language);
  }

  setTimeout(() => {
    SplashScreen.hide();
  }, 0);
});
</script>


<style lang="scss">
  .player-active {
    ion-content {
      --offset-bottom: -78px !important;
    }
  }
</style>
