<template>
  <div class="new-podcast pointer has-edit-button">
    <ion-card class="new-podcast-card is-flex">
      <EditButton
        v-if="canEdit"
        :url="`companies/${newPodcast.company_id}/courses/${newPodcast.id}/edit`"
      />
      <AppImg
        :img-src="banner"
        img-alt="banner"
        border-radius="10px"
        class="image-wrap"
      />
    </ion-card>
  </div>
</template>

<script>
import {
  IonCard,
  IonCardHeader,
  IonCardTitle,
  IonCardSubtitle,
  IonImg,
} from '@ionic/vue';
import AppImg from '@/components/common/AppImg.vue';
import { getSmallestSizeImage } from '@/helpers/functions';
import { computed } from '@vue/reactivity';
import EditButton from '@/components/common/EditButton.vue';
import { useStore } from 'vuex';

export default {
  name: 'NewPodcast',
  components: {
    IonCard,
    IonCardHeader,
    IonCardTitle,
    IonCardSubtitle,
    IonImg,
    AppImg,
    EditButton,
  },
  props: {
    newPodcast: {
      type: Object,
      required: true,
    },
  },

  setup (props) {
    const store = useStore();

    const banner = computed(() => getSmallestSizeImage(props.newPodcast.media)?.url ?? null );
    const canEdit = computed(() => {
      const hasPermission = store.getters['authentication/hasPermission']('update_course');
      const isWeb = store.state.app.platform === 'web';
      return hasPermission && isWeb;
    });

    return {
      banner,
      canEdit,
    };
  },
};
</script>

<style scoped lang="scss">
.new-podcast-card {
  align-items: center;
  text-align: left;
  margin: 10px 16px 10px 0;

  .image-wrap {
    width: 200px;
    height: 200px;
    margin-right: 10px;

    :deep(img) {
      width: 100%;
      height: 100%;
    }
  }
}
</style>
