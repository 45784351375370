import { useStore } from 'vuex';

export default function loggedInMiddleware(to, from, next) {
  const store = useStore();

  const isLogged = store.getters['authentication/isLogged'];

  if (isLogged) {
    const playerActive = store.state.player.playerActive;
    const playerCollapsed = store.state.player.playerCollapsed;

    if (playerActive && !playerCollapsed) {
      store.commit('player/setPlayerCollapse', true);
    }

    next('/main/home');
  } else {
    next();
  }
}
