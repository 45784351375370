<template>
  <div
    class="following-company pointer"
    :style="{ width }"
    @click="$router.push(`/main/following?id=${followingCompany.company.id}`)"
  >
    <ion-card class="following-company-card is-flex">
      <AppImg
        :img-src="banner"
        img-alt="banner"
        border-radius="10px"
        class="image-wrap"
      />
      <ion-card-header class="card-header">
        <ion-card-title class="card-title fz-10 fz-12-lg fw-500 is-displaying-two-lines-text">
          {{ followingCompany.company.name }}
        </ion-card-title>
      </ion-card-header>
    </ion-card>
  </div>
</template>

<script>
import {
  IonCard,
  IonCardHeader,
  IonCardTitle,
  IonCardSubtitle,
  IonImg,
} from '@ionic/vue';
import AppImg from '@/components/common/AppImg.vue';
import { getSmallestSizeImage } from '@/helpers/functions';
import { computed } from '@vue/reactivity';

export default {
  name: 'FollowingCompany',
  components: {
    IonCard,
    IonCardHeader,
    IonCardTitle,
    IonCardSubtitle,
    IonImg,
    AppImg,
  },
  props: {
    followingCompany: {
      type: Object,
      required: true,
    },
    width: {
      type: String,
      default: '',
    },
  },

  setup (props) {
    const banner = computed(() => getSmallestSizeImage(props.followingCompany.company.media)?.url ?? null );

    return {
      banner,
    };
  },
};
</script>

<style scoped lang="scss">
.following-company-card {
  align-items: center;
  text-align: left;
  margin: 10px 16px 10px 0;

  .image-wrap {
    width: 70px;
    min-width: 70px;
    height: 70px;
    margin-right: 10px;
  }
}

.card-header {
  padding-left: 0;
  padding-right: 0;
  padding: 0px;
  max-width: 50%;

  .card-subtitle {
    text-transform: none;
    --color: #fff;
  }
}
</style>
