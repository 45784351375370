<template>
  <IonIcon
    class="pointer"
    :icon="shareSocial"
    @click.stop="openShareModal"
  />
</template>

<script>
import {modalController, IonIcon} from '@ionic/vue';
import { shareSocial } from 'ionicons/icons';
import ShareModal from '@/components/common/ShareModal';
import { useI18n } from 'vue-i18n/index';


export default {
  name: 'ShareButton',
  components: {
    IonIcon,
  },
  props: {
    text: {
      type: String,
      default: '',
    },

    courseId: {
      type: [String, Number],
      required: false,
      default: null,
    },

    companyId: {
      type: [String, Number],
      required: false,
      default: null,
    },


    streamId: {
      type: [String, Number],
      required: false,
      default: null,
    },

    shareLink: {
      type: String,
      default: process.env.VUE_APP_URL,
    },
  },

  setup(props) {
    const { t } = useI18n();
    const getShareLink = () => {
      if (props.companyId) {
        return `${process.env.VUE_APP_SHARE_URL}/company/${props.companyId}`;
      }
      const base = `${process.env.VUE_APP_SHARE_URL}/course/${props.courseId}`;
      if (props.streamId) {
        return base + '?s=' + props.streamId;
      }
      return base;
    };

    const openShareModal = async () => {
      const modal = await modalController.create({
        component: ShareModal,
        componentProps: {
          shareLink: getShareLink(),
          shareText: props.text,
          courseId: props.courseId,
          streamId: props.streamId,
          modalTitle: props.companyId ? t('commonKeys.shareCompany') : null,
        },
        cssClass: 'share-modal',
      });
      await modal.present();
    };

    return { openShareModal, shareSocial };
  },
};
</script>

<style lang="scss" scoped>
ion-icon {
  font-size: 1.5rem;
}
</style>
