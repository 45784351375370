<template>
  <section
    v-if="popularData.length"
    class="mb-10"
  >
    <h1
      v-if="title"
      class="mb-5"
    >
      {{ title }}
    </h1>

    <ion-slides
      :options="slideOptions"
    >
      <ion-slide
        v-for="(data, index) in formattedData"
        :key="data.id"
      >

        <div class="top-number">
          {{ index + 1 }}
        </div>

        <ion-card
          color="medium"
          class="podcast-card pointer"
          @click="openPodcast(data)"
        >
          <AppImg
            :img-src="getImageUrl(formattedPodcastData(data))"
            :img-alt="`top${index + 1} podcast image`"
            border-radius="10px"
            class="podcast-card__img"
            is-transparent
          />

          <ion-card-header class="podcast-card__header">
            <ion-card-subtitle class="podcast-card__header__subtitle">{{ getStreamTitle(data.series_title) }}</ion-card-subtitle>
            <ion-card-title class="podcast-card__header__title">{{ getStreamTitle(data.stream_title) }}</ion-card-title>
          </ion-card-header>

          <ion-label class="podcast-card__duration">{{ Math.round(data.duration / 60) }} {{ $t('podcast.min') }}</ion-label>
        </ion-card>
      </ion-slide>
    </ion-slides>
  </section>
</template>

<script setup>
import { IonSlides, IonSlide, IonCard, IonCardHeader, IonCardSubtitle, IonCardTitle, IonLabel, IonImg } from '@ionic/vue';
import { computed, defineProps } from "vue";
import { useStore } from 'vuex';

import { getSmallestSizeImage } from '@/helpers/functions';
import AppImg from '@/components/common/AppImg.vue';

import {
  CATEGORY_GENERAL_MEDIA,
  CATEGORY_BANNER_MEDIA,
  CATEGORY_STREAM_STILL,
  CATEGORY_PODCAST_IMG } from '@/config/constants';

const PRIOR_IMAGES = [
  CATEGORY_PODCAST_IMG,
  CATEGORY_STREAM_STILL,
  CATEGORY_BANNER_MEDIA,
  CATEGORY_GENERAL_MEDIA,
];

const props = defineProps({
  title: {
    type: String,
    default: null,
  },
  popularData: {
    type: Array,
    default: () => [],
  },
});

const slideOptions = {
  slidesPerView: "auto",
};

const formattedData = computed(() =>
  props.popularData.map((data) => ({
    ...data,
    course_media: data.course?.media ?? [],
  })),
);

const formattedPodcastData = (data) => Object.assign({}, data, { course_media: data.course.media });

function getImageUrl (data) {
  const validStreamImages = data.media.filter(media => PRIOR_IMAGES.includes(media.category));

  validStreamImages.sort((a,b) => b.category - a.category);
  const streamImg = getSmallestSizeImage(validStreamImages)?.url ?? null;
  const courseImg = getSmallestSizeImage(data.course_media)?.url ?? null;

  return streamImg || courseImg;
};

function getStreamTitle (title) {
  if (title.length > 70) {
    return title.slice(0, 70) + '...';
  }

  return title;
};

const store = useStore();

const openPlayer = (id, course) => {
  const startAt = course.streams.findIndex((stream) => stream.id === id);
  store.dispatch('player/openPlayer', {
    course,
    company: course.company,
    playlist: course.streams,
    startAt,
  });
};

const openPodcast = (data) => {
  store
    .dispatch('course/setCourseData', data.course_id)
    .then((course) => {
      openPlayer(data.id, course);
    });
};

</script>

<style scoped lang="scss">
  @import "src/theme/custom";

  ion-slide {
    position: relative;
    max-width: 310px !important;
    margin: 0 10px;

    &:last-of-type {
      .top-number {
        width: 60px;
        height: 60px;
      }
    }

    .top-number {
      width: 50px;
      height: 50px;
      background-color: var(--ion-color-primary-shade);
      border-radius: 50%;
      display: flex;
      justify-content: center;
      align-items: center;
      font-size: 42px;
      z-index: 1;
      position: absolute;
      top: 0;
      left: -10px;
    }

    .podcast-card {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      position: relative;
      padding: 15px 20px 5px;
      border-radius: 20px;
      height: 310px;

      &__img {
        width: 100%;
        height: 180px;

        &:deep(img) {
          max-width: 180px;
          height: 100%;
        }
      }

      &__header {
        display: flex;
        flex-direction: column;
        padding: 20px 0 5px 0;

        &__subtitle {
          text-align: left;
          font-size: 10px;
          font-weight: 300;
        }

        &__title {
          text-align: left;
          font-size: 14px;
        }
      }

      &__duration {
        font-size: 10px;
        font-weight: 500;
        margin-left: auto;
        margin-bottom: 5px;
      }
    }
  }
</style>
