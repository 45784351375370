<template>
  <ion-header>
    <ion-toolbar>
      <div class="toolbar">
        <GreetingHeader />
        <Searchbar
          v-if="showSearch"
          v-model="searchInput"
          ref="searchbarRef"
          @update:modelValue="searchData"
        />
        <ion-buttons slot="primary">
          <ion-button
            color="light"
            @click="toggleSearch"
          >
            <IonIcon
              slot="icon-only"
              :icon="search"
            />
          </ion-button>
          <ion-button
            color="light"
            router-link="/main/profile?tab=profile"
          >
            <IonIcon
              slot="icon-only"
              :icon="personCircle"
            />
          </ion-button>
          <ion-button
            color="light"
            router-link="/main/profile?tab=settings"
          >
            <IonIcon
              slot="icon-only"
              :icon="settingsSharp"
            />
          </ion-button>
        </ion-buttons>
      </div>
    </ion-toolbar>
  </ion-header>
  <SearchPodcast
    v-if="searchInput.trim().length >= 3"
    :search-input="searchInput"
  />
</template>

<script>
import { defineComponent,  ref, nextTick, emit} from 'vue';
import { useStore } from 'vuex';

import {
  IonButtons,
  IonToolbar,
  IonButton,
  IonSearchbar,
  IonIcon,
  IonHeader,
} from '@ionic/vue';

import {
  personCircle,
  settingsSharp,
  search,
} from 'ionicons/icons';


import SearchPodcast from "@/views/SearchPodcast.vue";
import Searchbar from "@/components/home/Searchbar.vue";
import GreetingHeader from "@/components/home/GreetingHeader.vue";



export default defineComponent({
  name: 'Header',
  components: {
    IonButton,
    IonToolbar,
    IonButtons,
    IonSearchbar,
    IonIcon,
    IonHeader,

    GreetingHeader,
    SearchPodcast,
    Searchbar,
  },
  emits: ['showSearch'],

  setup(props, { emit }) {
    const store = useStore();
    const loading = ref(false);


    const showSearch = ref(false);
    const searchInput = ref('');

    const searchbarRef = ref(null);
    const toggleSearch = () => {
      showSearch.value = !showSearch.value;
      searchInput.value = '';
      nextTick(() => {
        if (searchbarRef.value) {
          searchbarRef.value.focusInput();
        }
      });
    };

    const searchData = () => {
      if (searchInput.value.trim().length < 3) {
        emit('showSearch', false);
        return;
      }
      emit('showSearch', true);
      loading.value = true;
      store.dispatch('search/searchPodcasts', searchInput.value.trim())
        .finally(() => {
          loading.value = false;
        });
    };



    return {
      search,
      personCircle,
      settingsSharp,

      searchbarRef,
      toggleSearch,
      showSearch,
      loading,
      searchInput,
      searchData,
    };
  },
});
</script>

<style lang="scss" scoped>
.toolbar{
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 60px;
}
.search-bar{
  width: 600px;
}
ion-searchbar {
 --border-radius: 8px;
}

:deep(.searchbar-search-icon){
    display: none !important;
  }
:deep(.searchbar-input){
    padding-left: 10px !important;
  }
</style>