import { useEventListener } from '@vueuse/core';
import { ref, onMounted, onUnmounted } from 'vue';
import debounce from 'lodash/debounce';

export default function useDeviceScreen() {
  const isMobileScreen = ref(false);
  const isDesktopScreen = ref(false);
  const isTabletScreen = ref(false);

  const handleResize = () => {
    const width =
      window.innerWidth ||
      document.documentElement.clientWidth ||
      document.body.clientWidth;

    if (width < 769) {
      isMobileScreen.value = true;
      isDesktopScreen.value = false;
      isTabletScreen.value = false;
    } else if (width > 1023) {
      isDesktopScreen.value = true;
      isMobileScreen.value = false;
      isTabletScreen.value = false;
    } else {
      isTabletScreen.value = true;
      isMobileScreen.value = false;
      isDesktopScreen.value = false;
    }
  };

  const debouncedResize = debounce(handleResize, 300);

  onMounted(() => {
    handleResize();
    useEventListener(window, 'resize', debouncedResize);
  });

  onUnmounted(() => {
    debouncedResize.cancel();
  });

  return {
    isMobileScreen,
    isTabletScreen,
    isDesktopScreen,
  };
}
