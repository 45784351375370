import axios from "@/http";
import {NEW_COURSE_RELEASES} from "@/config/api";

const initializeStates = () => {
  return {
    allCourses: [],

    newReleases: [],
    newReleasesHome: [],

    pagination: {
      offset: 0,
      limit: 10,
    },

    totalRecords: 0,
    originCurrentRecordsCount: 0,

    dataFetched: false,
  };
};

export const state = () => initializeStates();

export const actions = {
  setNewReleases ({ state, commit }, append) {
    return new Promise((resolve, reject) => {

      if (append) {
        commit('setPagination', { offset: state.pagination.offset + state.pagination.limit });
      } else {
        commit('setPagination', { offset: 0 });
      }

      const url = NEW_COURSE_RELEASES + `?limit=${state.pagination.limit}&offset=${state.pagination.offset}`;
      axios.get(url)
        .then(({ data }) => {
          commit('setNewReleases', { data: data.new_releases, append });
          commit('setNewReleasesTotal', data.totalRecords);
          commit('favorites/setFavoritesStreamsIds', { data: data.favourite_course_ids, flatArray: false }, {root: true});
          commit('favorites/setfollowedCompaniesIds', data.following_company_ids, {root: true});
          resolve(data);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  setNewReleasesHome ({ state, commit }, append) {
    return new Promise((resolve, reject) => {
      const url = NEW_COURSE_RELEASES;
      axios.get(url)
        .then(({ data }) => {
          commit('setNewReleasesHome', { data: data.new_releases, append });
          commit('favorites/setfollowedCompaniesIds', data.following_company_ids, {root: true});
          commit('favorites/setFavoritesStreamsIds', { data: data.favourite_course_ids, flatArray: false }, {root: true});
          resolve();
        })
        .catch((error) => {
          reject(error);
        });
    });
  },

};

export const mutations = {
  setNewReleases (state, { data, append }) {
    state.allCourses = [...data, ...state.allCourses];

    if (append) {
      state.newReleases.push(...data);
      state.originCurrentRecordsCount += data.length;
    } else {
      state.newReleases = data;
      state.originCurrentRecordsCount = data.length;
    }
    state.dataFetched = true;
  },


  setNewReleasesTotal (state, data) {
    state.totalRecords = data;
  },


  setPagination (state, data) {
    Object.assign(state.pagination, data);
  },


  setNewReleasesHome (state, { data, append }) {
    // data.sort((courseA, courseB) => {
    //   return (new Date(courseB.created_at)) - (new Date(courseA.created_at));
    // });

    if (append) {
      state.newReleasesHome.push(...data);
    } else {
      state.newReleasesHome = data;
    }
  },

  resetMobileState (state) {
    Object.assign(state, initializeStates());
  },
};

export const getters = {
  getNewReleases: state => state.newReleases,
  getNewReleasesHome: state => state.newReleasesHome,
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
