import useAppStorage from '@/composables/useAppStorage';
import {
  BIG_NUMBER_MAX_LENGTH,
  RECENTLY_PLAYED_STORAGE_KEY,
} from '@/config/constants';
import {
  DISPLAY_TYPE_MANUAL,
  DISPLAY_TYPE_CLOCK,
  DISPLAY_TYPE_VIDEO,
} from '@/config/constants';

import { Device } from '@capacitor/device';
const { getKey } = useAppStorage();

export const waitTime = (waitMs = 0) => {
  return new Promise(function (resolve) {
    setTimeout(() => {
      resolve(true);
    }, waitMs);
  });
};

export const getSmallestSizeImage = (imageArray, index = 0) => {
  if (!Array.isArray(imageArray)) {
    return null;
  }
  if (imageArray.length === 1) {
    return imageArray[0];
  }
  const sizes = ['small', 'medium', 'large', 'cropped', 'original'];
  for (let i = index; i < sizes.length; i++) {
    const image = imageArray.find((img) => img.size === sizes[i]);
    if (image) {
      return image;
    }
  }
  return null;
};

export const getTimeInSeconds = (str) => {
  const [hours, minutes, seconds] = str.split(':');
  return +hours * 60 * 60 + +minutes * 60 + +seconds;
};

export const getSecondsFromHMSString = (timeString) => {
  if (!timeString) {
    return null;
  }
  const parts = timeString.split(':');
  const h = parts[0] * 60 * 60;
  const m = parts[1] * 60;
  const s = parts[2] * 1;
  return h + m + s;
};

export const convertSecondsToTimeString = (seconds) => {
  return new Date(seconds * 1000).toISOString().substr(11, 8);
};

export const getPlayerLatestPosition = (streamUserArray) => {
  if (!streamUserArray || !streamUserArray.length) {
    return null;
  }
  const chapterObj = streamUserArray[0];
  return getSecondsFromHMSString(chapterObj.end_time);
};

export const getArrayItemFromLocalStorage = async (key) => {
  let array = await getKey(key);

  try {
    array = JSON.parse(array) ?? [];
  } catch (e) {
    array = [];
  }

  return array;
};

export const getObjectItemFromLocalStorage = async (key) => {
  let obj = await getKey(key);

  try {
    obj = JSON.parse(obj) ?? {};
  } catch (e) {
    obj = {};
  }

  return obj;
};

export const isObject = (value) => {
  return typeof value === 'object' && !Array.isArray(value) && value !== null;
};

export const getStreamItemFromStorage = async (streamId) => {
  const recentlyPlayed = await getArrayItemFromLocalStorage(
    RECENTLY_PLAYED_STORAGE_KEY,
  );
  return recentlyPlayed.find((played) => played.stream_id === this.streamId);
};

export const getCurrentUnix = (inMs = false) => {
  const unix = new Date().getTime();
  return inMs ? unix : Math.round(unix / 1000);
};

export const getLatestTrackPositionByTimestamp = (
  streamUser,
  storageStream,
) => {
  if (!streamUser && !storageStream) {
    return null;
  }

  const streamStorageTimestamp = storageStream?.timestamp ?? 0;
  const streamUserTimestamp = streamUser?.progress_updated_timestamp ?? 0;

  if (streamStorageTimestamp >= streamUserTimestamp) {
    return storageStream?.progress ?? null;
  }
  return streamUser?.progress_end_time ?? streamUser?.end_time ?? null;
};

export const checkPlatform = async () => {
  const info = await Device.getInfo();
  return info.platform;
};

export const convertStringHHMMSStoSec = (timeString) => {
  const parts = timeString.split(':');
  const h = parseInt(parts[0]) * 3600;
  const m = parseInt(parts[1]) * 60;
  const s = parseInt(parts[2]);
  return h + m + s;
};

export const getTimeInMilisec = (string) => {
  const currentDate = new Date();
  const year = currentDate.getFullYear();
  const month = currentDate.getMonth();
  const day = currentDate.getDate();
  const parts = string.split(':');
  const h = parseInt(parts[0]);
  const m = parseInt(parts[1]);
  const s = parseInt(parts[2]);

  return new Date(year, month, day, h, m, s).getTime();
};

export const showManually = (question, action) => {
  const displayMoments = question.display_moments.filter(
    (question) => question.action === action,
  );
  if (displayMoments.find((setting) => setting.type === DISPLAY_TYPE_MANUAL)) {
    return true;
  }
};
export const showByPlayerTime = (question, action, playerCurrentTime) => {
  const playerRanges = [];
  const videoPlayerMoments = question.display_moments.filter(
    (setting) =>
      setting.type === DISPLAY_TYPE_VIDEO && setting.action === action,
  );
  videoPlayerMoments.forEach((setting) => {
    const secondsStart = convertStringHHMMSStoSec(setting.time_open);
    const secondsEnd = convertStringHHMMSStoSec(setting.time_close);
    playerRanges.push({
      time_open: secondsStart,
      time_close: secondsEnd,
    });
  });

  for (let i = 0; i < playerRanges.length; i++) {
    if (
      playerCurrentTime >= playerRanges[i].time_open &&
      playerCurrentTime < playerRanges[i].time_close
    ) {
      return playerRanges[i].time_close - playerCurrentTime;
    }
  }
  return false;
};

export const showByCurrentTime = (question, action, currentTime) => {
  const localTimeMoments = question.display_moments.filter(
    (setting) =>
      setting.type === DISPLAY_TYPE_CLOCK && setting.action === action,
  );

  for (let i = 0; i < localTimeMoments.length; i++) {
    const start = getTimeInMilisec(localTimeMoments[i].time_open);
    const end = getTimeInMilisec(localTimeMoments[i].time_close);
    if (currentTime?.getTime() > start && currentTime?.getTime() < end) {
      return Math.floor((end - currentTime.getTime()) / 1000);
    }
  }
  return false;
};

export const professionBignumberMapper = (profession) => {
  if (profession && profession.bignumber && profession.bignumber.big_number) {
    profession.bignumber.big_number = String(
      profession.bignumber.big_number,
    ).padStart(BIG_NUMBER_MAX_LENGTH, '0');
  }
  return profession;
};

export const getApiVersion = (v, raw = false) => {
  if (!v) {
    return '/v1';
  }
  return raw ? v : `/v${v}`;
};
