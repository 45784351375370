<template>
  <div>
    <ion-label
      v-if="label"
      position="stacked fw-500"
      class="label"
    >
      {{ label }}
    </ion-label>

    <div>
      <div class="is-flex">
        <ion-item
          class="input-container"
          lines="none"
        >
          <ion-icon
            v-if="icon"
            :icon="icon"
            class="icon"
          />
          <ion-note
            v-if="note"
            class="input input-center"
            mode="ios"
          >{{ note }}</ion-note>
          <ion-input
            ref="input"
            :value="value"
            :max="max"
            :min="min"
            :name="name"
            :type="type === 'password' ? dynamicType : type"
            :readonly="readonly || loading"
            :disabled="disabled"
            :maxlength="maxlength"
            :inputmode="inputmode"
            :autocomplete="autocomplete"
            :minlength="minlength"
            :placeholder="placeholder"
            :clear-on-edit="false"
            @ionInput="handleInput"
            @ionBlur="handleBlur"
            class="input h-100"
            mode="ios"
            @keyup.enter="handleEnter"
          />
          <ion-icon
            v-if="type === 'password'"
            slot="end"
            mode="ios"
            :icon="isPasswordVisible ? eyeOff : eye"
            class="icon right-icon pointer"
            @click="isPasswordVisible = !isPasswordVisible"
          />
          <slot name="inner-right-addon"></slot>
          <ion-spinner
            v-if="loading"
            slot="end"
          />
        </ion-item>

        <slot name="right-addon"></slot>
      </div>

      <AppErrorField
        :errors="errors"
        :errors-all="errorsAll"
      />
    </div>
  </div>
</template>

<script>
import { IonLabel, IonInput, IonItem, IonIcon, IonNote, IonSpinner } from '@ionic/vue';
import { eye, eyeOff } from 'ionicons/icons';
import { computed, ref } from '@vue/reactivity';
import AppErrorField from "./AppErrorField";

export default {
  name: 'Input',
  components: {
    AppErrorField,
    IonInput,
    IonLabel,
    IonItem,
    IonIcon,
    IonNote,
    IonSpinner,
  },
  props: {
    label: {
      type: String,
      default: null,
    },
    value: {
      type: [String, Number],
      default: null,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    max: {
      type: String,
      default: undefined,
    },
    maxlength: {
      type: [Number, String],
      default: undefined,
    },
    min: {
      type: String,
      default: undefined,
    },
    minlength: {
      type: [Number, String],
      default: undefined,
    },
    name: {
      type: String,
      default: '',
    },
    placeholder: {
      type: String,
      default: '',
    },
    readonly: {
      type: Boolean,
      default: false,
    },
    type: {
      type: String,
      default: 'text',
    },
    icon: {
      type: String,
      default: null,
    },
    note: {
      type: String,
      default: null,
    },
    loading: {
      type: Boolean,
      default: false,
    },
    inputmode: {
      type: String,
      default: undefined,
    },
    errors: {
      type: Array,
      default() {
        return [];
      },
    },
    errorsAll: {
      type: Boolean,
      default: false,
    },
    autocomplete: {
      type: String,
      default: undefined,
    },
  },

  emits: ['input:update', 'input:enter', 'blur'],

  setup(_, { emit }) {
    const input = ref(null);

    const isPasswordVisible = ref(false);
    const dynamicType = computed(() => isPasswordVisible.value ? 'text' : 'password');

    const handleInput = (e) => {
      emit('input:update', e.target.value);
    };
    const handleEnter = () => {
      emit('input:enter');
    };
    const handleBlur = (e) => {
      emit('blur', e);
    };

    return {
      handleInput,
      handleBlur,
      handleEnter,
      input,
      dynamicType,
      eye,
      eyeOff,
      isPasswordVisible,
    };
  },
};
</script>

<style scoped lang="scss">

ion-note {
  margin-right: 10px;
}

.input-container {
  flex: 1;
  --padding-start: 10px;
  --inner-padding-start: 0;
  --inner-padding-end: 0;
  --padding-end: 0;
  --inner-padding-top: 0;
  --inner-padding-bottom: 0;
  --border-radius: 10px;
  --border-style: solid;
  --border-width: 0px;
  --border-color: #fff;
  --background: #282828;
  --min-height: 50px;
}

.input {
  --background: transparent;
  --color: var(--white);
  --placeholder-color: var(--white);
  --padding-start: 10px !important;
  border-radius: 0;
  --placeholder-opacity: 0.5;
  font-size: 14px;
}

.icon {
  opacity: 0.8;
  color: var(--white);
  font-size: 20px;
  margin-left: 10px;
}
.icon.right-icon {
  margin: 10px;
}

.label {
  color: var(--ion-color-light);
}

.input-center{
  align-self: center;
}
</style>
