<template>
  <app-lazy
    v-for="(data, i) in sortedFeaturedCourses"
    :key="`featured-${i}`"
    :style="getSliderStyle(data)"
  >
    <FeaturedSlider
      :key="`featured-${i}`"
      :featured-slider-data="data"
    />
  </app-lazy>
</template>

<script>
import FeaturedSlider from '@/components/featured/FeaturedSlider.vue';
import { computed } from '@vue/reactivity';
import AppLazy from "@/components/common/AppLazy";

export default {
  name: 'FeaturedSlidersContainer',
  components: {
    FeaturedSlider,
    AppLazy,
  },
  props: {
    featuredSlidersData: {
      type: Array,
      required: true,
    },
  },
  setup (props) {
    const sortedFeaturedCourses = computed(() => [...props.featuredSlidersData].sort((a, b) => a[0].themes[0].position - b[0].themes[0].position));
    const getSliderStyle = (data) => {
      const order = data?.[0]?.themes?.[0]?.position ?? 0;
      return { order };
    };
    return {sortedFeaturedCourses, getSliderStyle};
  },
};
</script>
