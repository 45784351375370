export const NOTIFICATIONS_ASK_COUNT_KEY = 'notification_ask_count';
export const NOTIFICATIONS_ASK_MAX_ATTEMPTS = 3;

export const RECENTLY_PLAYED_STORAGE_KEY = 'recently_played';

export const RECENTLY_PLAYED_PROGRESS_STORAGE_KEY = 'recently_played_progress';
export const RECENTLY_PLAYED_MAX_COUNT = 10;

export const INVITE_TOKEN_PASSED_STORAGE_KEY = 'invite_passed';

export const INIT_APP_NETWORK_WAITING = 'waiting';
export const INIT_APP_NETWORK_CONNECTED = 'connected';

export const DEEP_LINK_REDIRECT_STORAGE_KEY = 'deeplink_redirect';
export const NOT_FOUNT_REDIRECT_STORAGE_KEY = 'url404';
export const IS_INVITE_CARD_CLOSED_FIRST = 'invite_closed_first';
export const INVITE_CLOSED_COUNTER = 'invite_closed_counter';

// categories of media for an entity
export const CATEGORY_GENERAL_MEDIA = 0;
export const CATEGORY_LOGO = 1;
export const CATEGORY_MAIN_MEDIA = 2;
export const CATEGORY_PROFILE_PIC = 3;
export const CATEGORY_BANNER_MEDIA = 4;
export const CATEGORY_STREAM_STILL = 5;
export const CATEGORY_PRESENTATION = 6;
export const CATEGORY_HANDOUT = 7;
export const CATEGORY_REFERENCES = 8;
export const CATEGORY_PODCAST_AUDIO = 9;
export const CATEGORY_PODCAST_IMG = 10;

/* eslint-disable no-useless-escape */
export const domainRegex =
  /^(?:https?:\/\/)?(?:[^@\/\n]+@)?(?:www\.)?([^:\/?\n]+)/;

export const PUSH_ACTION_REDIRECT = 'redirect';

// tracking actions
export const TRACKING_PODCAST_VIEW = 'podcast_view';
export const TRACKING_PUBLIC_PODCAST_VIEW = 'medicast_public_podcast_view';
export const TRACKING_PODCAST_PLAY = 'podcast_play';
export const TRACKING_PODCAST_PAUSE = 'podcast_pause';
export const TRACKING_PODCAST_SKIP_SEC_FORWARD = 'podcast_skip_seconds_forward';
export const TRACKING_PODCAST_SKIP_SEC_BACKWARD =
  'podcast_skip_seconds_backward';
export const TRACKING_PODCAST_SKIP_STREAM_FORWARD =
  'podcast_skip_stream_forward';
export const TRACKING_PODCAST_SKIP_STREAM_BACKWARD =
  'podcast_skip_stream_backward'; // done above
export const TRACKING_PODCAST_SCRUBBING = 'podcast_scrubbing';

export const TRACKING_COURSE_VIEW = 'course_podcast_view';
export const TRACKING_PUBLIC_COURSE_VIEW = 'medicast_public_channel_view';
export const TRACKING_FEATURED_COURSE_VIEW = 'featured_podcast_view';
export const TRACKING_HOME = 'medicast_home';
export const TRACKING_LIBRARY = 'medicast_library';
export const TRACKING_FAVORITES = 'medicast_favorites';
export const TRACKING_FOLLOWING = 'medicast_following';
export const TRACKING_NEW_RELEASES = 'medicast_new_releases';
export const TRACKING_ACCOUNT = 'medicast_account';
export const TRACKING_PROFILE = 'medicast_profile';
export const TRACKING_SETTINGS = 'medicast_settings';
export const TRACKING_SHARE_STREAM = 'medicast_share_stream';
export const TRACKING_SHARE_COURSE = 'medicast_share_course';
export const TRACKING_DEEPLINK_ACTION = 'medicast_share_link_opened';
export const TRACKING_NOTIFICATION_ACTION = 'medicast_notification_click';
export const TRACKING_MAIL_CONTENT_CLICK = 'mail_content_click';

// password validation
export const REGEX_SMALL_LETTERS = /[a-z]/;
export const REGEX_CAPITAL_LETTERS = /[A-Z]/;
export const REGEX_SPECIAL_SYMBOL = /[~`!@#$%^&*()+=\-_[\]\\';.,/{}|\\':<>\?]/;
export const REGEX_NUMBER = /\d/;
export const PASSWORD_MIN_LENGTH = 7;

export const GOOGLE_AUTHENTICATOR_DOWNLOAD_APP =
  'https://play.google.com/store/apps/details?id=com.google.android.apps.authenticator2&hl=uk&gl=US';
export const IOS_AUTHENTICATOR_DOWNLOAD_APP =
  'https://apps.apple.com/ru/app/google-authenticator/id388497605';
export const TWO_FA_CODE_LENGTH = 6;

export const STREAM_PROGRESS_HISTORY_STORAGE_KEY =
  'progress.history.stream.{id}';
export const STREAM_PROGRESS_HISTORY_STORAGE_TTL = 24 * 60 * 60 * 1000; // 24hr
export const STREAM_PROGRESS_HISTORY_STORAGE_KEY_REGEX =
  /^progress\.history\.stream\.(\d+)$/;

export const CAP2_MIGRATED_STORAGE_KEY = 'cap2_storage_migrated';

// accreditation
export const DISPLAY_TYPE_MANUAL = 'MANUAL';
export const DISPLAY_TYPE_CLOCK = 'CLOCK';
export const DISPLAY_TYPE_VIDEO = 'VIDEO';
export const DISPLAY_ACTION_SHOW_QUESTION = 'show_question';
export const DISPLAY_ACTION_SHOW_RESULTS = 'publish_results';
export const DISPLAY_ACTION_SHOW_ANSWERS = 'publish_answer';

export const getDefaultProfessionIds = () => [{ id: null }];

export const BIG_NUMBER_MAX_LENGTH = 11;

export const TOOLTIP_STORAGE_KEYS = {
  FAVORITE: 'tooltip_favorite_viewed',
  FOLLOW: 'tooltip_followed_viewed',
};

export const SELECTION_PROFESSIONS_LAYOUT = {
  DEFAULT: 'DEFAULT',
  BIG_ONLY: 'BIG_ONLY',
  PROFESSION_ONLY: 'PROFESSION_ONLY',
  ADDITION_ONLY: 'ADDITION_ONLY',
};

export const JIRA_CONTACT_LINK =
  'https://medischescholing.atlassian.net/servicedesk/customer/portal/1/group/1/create/9';
