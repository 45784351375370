import { useStore } from 'vuex';
import { useI18n } from 'vue-i18n';
import { alertController } from '@ionic/vue';
import useRequestToast from '@/composables/useRequestToast';
import { useRouter } from 'vue-router';

export default function useFavorites() {
  const store = useStore();
  const { openSuccessToast } = useRequestToast();
  const { t } = useI18n();
  const router = useRouter();

  const isFavorite = (courseId, streamId) => {
    if (streamId) {
      const favoritesStreamsIds =
        store.getters['favorites/getFavoritesStreamsIds'];
      return favoritesStreamsIds.includes(streamId);
    } else {
      const favoritesCoursesIds =
        store.getters['favorites/getFavoritesCoursesIds'];
      return favoritesCoursesIds.includes(courseId);
    }
  };

  const deleteFavorite = async (course_id, stream_id = '') => {
    const payload = {
      course_id,
      user_id: store.getters['authentication/getUser'].id,
      stream_id,
    };

    if (stream_id) {
      await store.dispatch('course/unfavoriteStream', payload);
    } else {
      await store.dispatch('course/unfavoriteCourse', payload);
    }
  };

  const deleteFavoritePrompt = async (
    course_id,
    stream_id = '',
    entity_type,
  ) => {
    const alert = await alertController.create({
      header: t(`savedCourses.dialogPromptTitle.${entity_type}`),
      message: t(`savedCourses.dialogPromptMessage.${entity_type}`),
      buttons: [
        { text: t('savedCourses.dialogCancelText') },
        {
          text: t('savedCourses.dialogConfirmText'),
          handler: () => deleteFavorite(course_id, stream_id),
        },
      ],
    });
    return alert.present();
  };

  const toggleFavourite = async (course_id, stream_id = '', entity_type) => {
    const payload = {
      course_id,
      user_id: store.getters['authentication/getUser'].id,
      stream_id,
    };

    if (!store.getters['authentication/isLogged']) {
      store.dispatch('player/closePlayer');
      router.push('/login');
      return;
    }

    if (isFavorite(course_id, stream_id)) {
      await deleteFavoritePrompt(course_id, stream_id, entity_type);
    } else {
      if (stream_id) {
        await store.dispatch('course/favoriteStream', payload);
      } else {
        await store.dispatch('course/favoriteCourse', payload);
      }
      openSuccessToast(t(`savedCourses.courseSavedMessage.${entity_type}`));
    }
  };

  return {
    isFavorite,
    toggleFavourite,
  };
}
