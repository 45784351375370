import axios from '@/http';

import { GET_FEATURED_COURSES_URL } from '@/config/api';

const initializeStates = () => {
  return {
    featuredCoursesDetails: [],
  };
};

export const state = () => initializeStates();

// getters

// actions
const actions = {
  setFeaturedDetails({ commit }, params) {
    return new Promise((resolve, reject) => {
      axios
        .get(GET_FEATURED_COURSES_URL, { params })
        .then(({ data }) => {
          commit('setFeaturedDetails', data);
          resolve(data);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
};

// mutations
const mutations = {
  setFeaturedDetails(state, data) {
    const coursesToHide = ['Medicast originals'];

    const newValue = Object.entries(data.featured_courses)
      .filter(([key]) => !coursesToHide.includes(key))
      .map(([, value]) => value);

    state.featuredCoursesDetails = newValue;
  },
};

export default {
  namespaced: true,
  state,
  actions,
  mutations,
};
