<template>
  <IonIcon
    class="pointer"
    :="$attrs"
    :icon="isFavorite(props.courseId, props.streamId) ? heart : heartOutline"
    @click.stop="toggleFavourite(props.courseId, props.streamId, props.entityType)"
  />
</template>

<script setup>
import { defineProps, defineEmits } from 'vue';
import { IonIcon } from '@ionic/vue';
import { heartOutline, heart } from 'ionicons/icons';
import useFavorites from '@/composables/useFavorites';

const props = defineProps({
  courseId: {
    type: [String, Number],
    required: true,
  },
  streamId: {
    type: [String, Number],
    default: '',
  },
  entityType: {
    type: String,
    default: 'episode',
  },
});

const emit = defineEmits(['update:favorites']);

const { isFavorite, toggleFavourite } = useFavorites(props.courseId, props.streamId);
</script>

<style lang="scss" scoped>
ion-icon {
  font-size: 1.5rem;
}
</style>
