import { createRouter, createWebHistory } from '@ionic/vue-router';
import MainTabs from '@/views/MainTabs.vue';
import Home from '@/views/Home.vue';
import loggedInMiddleware from '@/middleware/loggedIn';

/**
 * @info meta.player - defined if player is available in route
 **/

const routes = [
  {
    path: '/',
    redirect: '/main/home',
  },

  {
    path: '/main/',
    name: 'MainTabs',
    component: MainTabs,
    meta: {
      auth: true,
      player: true,
    },
    children: [
      {
        path: '',
        redirect: '/main/home',
        player: true,
      },
      {
        path: 'home',
        name: 'Home',
        component: Home,
        player: true,
      },
      {
        path: 'following',
        name: 'Following',
        component: () =>
          import(/* webpackChunkName: "favorites" */ '@/views/Following.vue'),
        meta: {
          auth: true,
          player: true,
        },
      },
      {
        path: 'profile',
        name: 'Profile',
        component: () =>
          import(/* webpackChunkName: "profile" */ '@/views/Profile.vue'),
        meta: {
          auth: true,
          player: true,
        },
      },
      {
        path: 'following',
        name: 'Following',
        component: () =>
          import(/* webpackChunkName: "following" */ '@/views/Following.vue'),
        meta: {
          auth: true,
          player: true,
        },
      },
      {
        path: 'search',
        name: 'SearchPodcast',
        component: () =>
          import(/* webpackChunkName: "search" */ '@/views/SearchPodcast.vue'),
        meta: {
          auth: true,
          player: true,
        },
      },
      {
        path: '/company/:companyId',
        name: 'Company',
        component: () =>
          import(
            /* webpackChunkName: "podcast-series" */ '@/views/Company.vue'
          ),
        meta: {
          auth: true,
          player: true,
        },
      },
    ],
  },
  {
    path: '/login',
    name: 'Login',
    component: () =>
      import(/* webpackChunkName: "login" */ '@/views/auth/Login.vue'),
    beforeEnter: loggedInMiddleware,
  },
  {
    path: '/sign-up',
    name: 'Sign-up',
    component: () =>
      import(/* webpackChunkName: "register" */ '@/views/auth/SignUp.vue'),
  },

  {
    path: '/verify',
    name: 'Verify',
    component: () =>
      import(/* webpackChunkName: "verify" */ '@/views/auth/Verify.vue'),
    meta: {
      auth: true,
    },
  },
  {
    path: '/verify-email',
    name: 'VerifyEmail',
    component: () =>
      import(/* webpackChunkName: "verify" */ '@/views/VerifyEmail.vue'),
  },

  {
    path: '/complete-profile',
    name: 'CompleteProfile',
    component: () =>
      import(
        /* webpackChunkName: "complete-profile" */ '@/views/auth/CompleteProfile.vue'
      ),
    meta: {
      auth: true,
    },
  },
  {
    path: '/forgot-password',
    name: 'ForgotPassword',
    component: () =>
      import(
        /* webpackChunkName: "forgot-password" */ '@/views/auth/ForgotPassword.vue'
      ),
    meta: {
      auth: false,
    },
  },
  {
    path: '/forgot-email',
    name: 'ForgotEmail',
    component: () =>
      import(
        /* webpackChunkName: "forgot-email" */ '@/views/auth/ForgotEmail.vue'
      ),
    meta: {
      auth: false,
    },
  },
  {
    path: '/course/:courseId',
    name: 'PublicPodcastSeries',
    component: () =>
      import(
        /* webpackChunkName: "podcast-series" */ '@/views/PublicPodcastSeries.vue'
      ),
    meta: {
      player: true,
    },
  },
  {
    path: '/contact',
    name: 'Contact',
    component: () =>
      import(/* webpackChunkName: "contact" */ '@/views/Contact.vue'),
    meta: {
      player: true,
    },
  },
  {
    path: '/continue-watching',
    name: 'ContinueWatching',
    component: () =>
      import(
        /* webpackChunkName: "continue-watching" */ '@/views/ContinueWatching.vue'
      ),
    meta: {
      auth: true,
      player: true,
    },
  },
  {
    path: '/invite/:inviteToken',
    name: 'DownloadApp',
    component: () =>
      import(/* webpackChunkName: "download-app" */ '@/views/DownloadApp.vue'),
  },
  {
    path: '/:pathMatch(.*)*',
    name: '404',
    component: () => import(/* webpackChunkName: "404" */ '@/views/404.vue'),
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

export default router;
