<template>
  <div
    :class="{ 'bg-white': isBackgroundWhite && !isTransparent }"
    class="relative"
  >
    <template v-if="props.showSkeleton">
      <ion-skeleton-text
        v-if="loading"
        :animated="true"
        :style="{ borderRadius, ...imgStyle }"
        class="ion-no-margin"
      />
    </template>
    <template v-else>
      <img
        v-if="loading"
        :src="defaultImg"
        :alt="imgAlt"
      />
    </template>

    <img
      :src="src"
      :alt="imgAlt"
      :class="{ 'opacity-0': loading }"
      :style="{ objectFit: imgFit, borderRadius, ...imgStyle }"
      @error="handleError"
      @load="handleLoad"
    />
  </div>
</template>

<script setup>
import { IonSkeletonText } from '@ionic/vue';
import { computed, ref, defineProps } from 'vue';
import defaultImg from '/public/assets/default.jpg';

const props = defineProps({
  imgSrc: {
    type: String,
    default: null,
  },
  imgAlt: {
    type: String,
    default: '',
  },
  imgFit: {
    type: String,
    default: 'cover',
  },
  borderRadius: {
    type: String,
    default: '0',
  },
  imgStyle: {
    type: Object,
    default: () => ({}),
  },
  isTransparent: {
    type: Boolean,
    default: false,
  },
  showSkeleton: {
    type: Boolean,
    default: true,
  },
});

const loading = ref(true);
const src = computed(() => props.imgSrc || defaultImg);
const isBackgroundWhite = computed(() => !loading.value && props.imgSrc?.slice(-3) === 'png' ? true : false);

const handleError = (event) => {
  event.target.src = defaultImg;
  loading.value = false;
};
const handleLoad = () => {
  loading.value = false;
};
</script>

<style lang="scss" scoped>
.relative {
  position: relative;
}

.opacity-0 {
  opacity: 0;
  position: absolute;
};

.bg-white {
  background: var(--white);
}

img {
  overflow: hidden;
  transition: opacity .25s;
}

ion-skeleton-text {
  background-color: rgb(69, 69, 69);
}
</style>