export default {
  commonKeys: {
    skipBtn: 'Overslaan',
    verifyBtn: 'VERIFIEREN',
    errorResponseText: 'Foutmelding',
    upload: 'Uploaden',
    edit: 'Bewerken',
    cancel: 'Annuleer',
    discount: 'Korting',
    online: 'Online',
    free: 'Gratis',
    apply: 'Toepassen',
    loadMore: 'Laad meer',
    loading: 'Laden',
    back: 'Terug',
    search: 'Zoeken',
    menu: 'Menu',
    close: 'Sluiten',
    none: 'Leeg',
    all: 'Alle',
    read: 'Bekijken',
    create: 'Aanmaken',
    update: 'Bijwerken',
    delete: 'Verwijder',
    yes: 'Ja',
    no: 'Nee',
    title: 'Titel',
    description: 'Omschrijving',
    view: 'Bekijk',
    publish: 'Publiceer',
    send: 'Verstuur',
    remove: 'Verwijder',
    save: 'Opslaan',
    active: 'Actief',
    disabled: 'Inactief',
    answer: 'Beantwoord',
    copyToClipBoard: 'Kopieer naar clipboard',
    successfullySaved: 'Opgeslagen',
    successfullyDeleted: 'Verwijderd',
    successfullyPublished: 'Gepubliceerd',
    successfullyUnPublished: 'Publicatie ongedaan gemaakt',
    successfullyCloned: 'Gedupliceerd',
    successfullySent: 'Verstuurd',
    notAuthorised: 'Geen toestemming om deze pagina te bekijken',
    confirm: 'Bevestigen',
    archivedSuccessfully: 'Cursus gearchiveerd',
    unarchivedSuccessfully: 'Uit archief gehaald',
    successfullyUpdated: 'Bijgewerkt',
    successfullyCreated: 'Aangemaakt',
    ok: 'Oke',
    contact: 'Contactformulier',
    continue: 'Ga door',
    download: 'Download',
    enabled: 'Inschakelen',
    googlePlayMarket: 'Google Play Store',
    appStore: 'App store',
    submit: 'Verstuur',
    disable: 'Disable',
    enable: 'Inschakelen',
    next: 'Volgende',
    homePage: 'Home page',
    clear: 'Leeg',
    clickToSelect: 'Klik om te selecteren',
    on: 'Aan',
    off: 'Uit',
    add: 'Toevoegen',
    noDataFound: 'Geen datum gevonden',
    noResultText: 'Geen resultaten',
    preview: 'Preview',
    label: 'Label',
    date: 'Datum',
    skip: 'Overslaan',
    fieldRequired: 'veld verplicht',
    saveAndContinue: 'Opslaan en doorgaan',
    archive: 'Archiveren',
    required: 'Verplicht',
    optional: 'Optioneel',
    login: 'Inloggen',
    logout: 'Uitloggen',
    register: 'Registreren',
    subscribers: 'volgers',
    open: 'Open',
    verification: 'Verificatie',
    noConnection: 'Er is geen internet verbinding beschikbaar',
    continueWithoutLogin: 'LUISTEREN ZONDER LOGIN',
    noAccess: 'Je moet ingelogd zijn om naar deze podcast te luisteren',
    unknown: 'Onbekend',
    here: 'hier',
    shareCompany: 'Deel organisatie',
  },
  mainNav: {
    home: 'Home',
    series: 'Serie',
    favorites: 'Favoriet',
    library: 'Bibliotheek',
  },
  login: {
    title: 'Inloggen',
    subtitle: 'Log in met uw MedischeScholing account.',
    emailLabel: 'E-mailadres',
    passwordLabel: 'Wachtwoord',
    loginBtnText: 'Inloggen',
    createAccountBtnText: 'Account aanmaken',
    invalidEmailText: 'Ongeldig e-mailadres',
    forgotPasswordLink: 'Wachtwoord vergeten',
    forgotEmailLink: 'Email vergeten',
    aboutUsLink: 'Over ons',
    contactLink: 'Contact',
    verifyCode: 'Code verifier',
    donthave: 'Nog geen account?',
    alreadyLogged: 'U bent al ingelogd',
    instruction1:
      'Medicast is een gratis product van MedischeScholing. Log in met uw bestaande MedischeScholing account waarmee u ook uw BIG-nummer heeft geregistreerd.',
    instruction2:
      'Let op! U kunt uw BIG-nummer maar onder 1 account registreren. Bent u uw email vergeten, gebruik dan de email vergeten functie en voer hier uw BIG-nummer in.',
    instruction3:
      'Heeft u nog geen MedischeScholing account? Klik dan op “registeren“. Heeft u vragen, neem dan contact met ons op via het contactformulier.',
    loginWith: 'Log in met uw',
  },
  logout: {
    label: 'Uitloggen',
    dialogPromptTitle: 'Uitloggen',
    dialogPromptMessage: 'Weet u zeker dat u wilt uitloggen?',
  },
  passwordRepeat: {
    min: 'minimaal',
    need: 'vereist',
    label: 'Wachtwoord',
    '8Chars': '8 tekens',
    passwordMatch: 'Wachtwoord match',
    '1CapitalLetter': '1 hoofdletter',
    '1SpecialCharacter': '1 speciaal teken',
    '1Number': '1 cijfer',
    '1SmallLetter': '1 kleine letter',
    repeatPass: 'Herhaal wachtwoord',
    passwordRepeat: 'Herhaal wachtwoord',
  },
  register: {
    title: 'Registreren',
    subtitle: 'Maak een gratis account aan.',
    emailLabel: 'Email',
    iAgreeText: 'Ik ga akkoord met de',
    termsConditionsText: 'algemene voorwaarden',
    createAccountBtnText: 'Medische Scholing account aanmaken',
    alreadyHaveAccountText: 'Ik heb al een Medische Scholing account',
    invalidEmailText: 'Ongeldig e-mailadres',
    verifyEmailMessage:
      'U ontvangt een e-mail met een link om uw e-mailadres te bevestigen.',
    emailVerified: 'E-mailadres geverifieerd',
    termsNotAccepted: 'De algemene voorwaarden moeten geaccepteerd worden',
  },
  resetPassword: {
    title: 'Reset wachtwoord',
    submitBtn: 'Aanvragen',
    invalidUrl:
      'De URL voor het opnieuw instellen van het wachtwoord is ongeldig.',
    pleaseRetry: ' Vraag een nieuwe URL aan door hier te klikken.',
  },
  contact: {
    us: 'Neem contact met ons op',
    subtitle:
      'MedischeScholing.nl streeft ernaar uw bericht binnen 48 uur te beantwoorden. Heeft u vragen over accreditatie? Vermeld dan uw BIG- of registratienummer in uw bericht.',
    name: 'Naam',
    email: 'E-mailadres',
    phone: 'Telefoon',
    subject: 'Onderwerp',
    message: 'Bericht',
    button: 'Verzenden',
    contactVia: 'Contact via',
    popupTitle: 'We nemen zo snel mogelijk contact met u op.',
    popupMessage: 'Hartelijk dank voor uw bericht.',
    googlePowered: 'Deze pagina wordt beschermd door reCAPTCHA van Google',
    googlePrivacy: 'Google privacy policy',
    googleTerm: 'Google algemene voorwaarden',
    apply: 'Toepassen',
    faqs: 'Veelgestelde vragen',
    search: 'Zoeken',
    choose: 'Kiezen',
    courseInvalid: {
      title: 'Informatie niet actueel?',
      reasonLabel: 'Welke informatie is niet actueel?',
      reasonPlaceholder: 'Kies optie',
      messageLabel: 'Kunt u aangeven wat niet actueel is?',
      button: 'Verzenden',
      openModalButton: 'Cursus datums incorrect',
      popupMessage:
        'Hartelijk dank voor deze melding, we proberen uw verzoek zo snel mogelijk te verwerken.',
      reasons: {
        option1: 'Inschrijflink werkt niet',
        option2: 'Datum(s) kloppen niet',
        option3: 'Cursus is verlopen',
        option4: 'Cursus is geannuleerd',
        otherOption: 'Andere informatie klopt niet',
      },
    },
    courseSuggest: {
      openModalButtonText: 'Cursus aanmelden',
      title: 'Cursus aanmelden',
      nameLabel: 'Naam',
      namePlaceholder: 'uw naam',
      emailLabel: 'E-mail',
      emailPlaceholder: 'Uw e-mail',
      courseTitleLabel: 'Cursus titel',
      courseTitlePlaceholder: 'Cursus titel',
      courseUrlLabel: 'Cursus link',
      courseUrlPlaceholder: 'Cursus link',
      descriptionLabel: 'Omschrijving',
      descriptionPlaceholder: 'Omschrijving',
      submitButton: 'Verzenden',
      popupMessage:
        'Hartelijk dank voor uw aanmelding, we proberen uw verzoek zo snel mogelijk te verwerken.',
    },
    need_assistance: 'hulp nodig, klik ',
  },
  profile: {
    languageChange: 'Taal gewijzigd',
    specialOffers: {
      never: 'Nooit',
      monthly: 'Maandelijks',
      weekly: 'Wekelijks',
      daily: 'Dagelijks',
    },
    welcomeText: 'Welkom bij',
    specialOffersLabel: 'Speciale aanbiedingen',
    accountTitleText: 'Account',
    emailLabel: 'E-mailadres',
    resetEmailBtnText: 'Stuur verificatiemail opnieuw',
    emailSentMessage: 'E-mail verzonden',
    emailResendNotification:
      'Het email-adres van uw account is nog niet geverifieerd. We hebben uw een email gestuurd met een link waarmee u uw email kunt verifiëren.',
    streetLabel: 'Straatnaam',
    numberLabel: 'Huisnummer',
    additionLabel: 'Toevoeging',
    postalCodeLabel: 'Postcode',
    cityLabel: 'Woonplaats',
    salutationLabel: 'Aanhef',
    titleLabel: 'Titel',
    initialsLabel: 'Voorletter(s)',
    firstNameLabel: 'Voornaam',
    insertionLabel: 'Tussenvoegsel',
    lastNameLabel: 'Achternaam',
    dobLabel: 'Geboortedatum',
    companyNameLabel: 'Naam van organisatie',
    kvkLabel: 'KvK-nummer',
    btwLabel: 'BTW-nummer',
    agbLabel: 'AGB-code',
    registrationLabel: 'Registratienummer',
    descriptionLabel: 'Omschrijving',
    sendOffersLabel: 'Ik ontvang graag aanbiedingen',
    chooseFrequencyPlaceHolder: 'Kies frequentie',
    sendMeOffersCheckBox:
      'Ik word graag op de hoogte gehouden over cursussen in het buitenland',
    profileUpdatedMessage: 'Profiel bijgewerkt',
    successDialogTitle: 'Opgeslagen',
    dialogConfirmText: 'Oké',
    invalidBigNumberMessage:
      'Als uw BIG-nummer uit minder dan 11 cijfers bestaat, voeg dan (een) voorloopnul(len) toe zodat u in totaal 11 cijfers hebt',
    bigNotFound: 'Uw BIG-nummer kan niet gevonden worden.',
    detailsNotFound: 'Details BIG-nummer niet gevonden',
    bigNumberServiceDown:
      'Details van het BIG-nummer kunnen momenteel niet worden geladen. Gelieve het op een later moment opnieuw te proberen.',
    currentPassword: 'Huidig wachtwoord',
    changePassword: 'Wachtwoord aanpassen',
    updateProfile: 'Profiel bijwerken',
    changePasswordSuccess:
      'Uw wachtwoord is aangepast. Log opnieuw in om door te gaan',
    changeEmail: 'E-mailadres wijzigen',
    currentEmail: 'Huidig e-mailadres',
    newEmail: 'Nieuw e-mailadres',
    password: 'Wachtwoord',
    newPassword: 'Nieuw wachtwoord',
    enterPassword: 'Wachtwoord invoeren',
    verifyPassword: 'Wachtwoord verifiëren',
    enterNewEmail: 'Nieuw e-mailadres invoeren',
    emailChangeSuccess:
      'Uw e-mailadres is aangepast. U kunt nu inloggen met uw nieuwe e-mailadres.',
    userRoles: 'Rollen',
    bignumberChangeSuccess: 'BIG-nummer bijgewerkt',
    deleteProfileImage: 'Verwijder profielfoto',
    deleteImageConfirmation: 'Weet u zeker dat u deze foto wilt verwijderen?',
    imageDeleteSuccess: 'Profiel foto verwijderd',
    gallery: 'Galerij',
    camera: 'Camera',
    profileImage: 'Profielfoto',
    current: 'Huidig',
    minHeight: 'Minimaal vereiste hoogte is ',
    minWidth: 'Minimaal vereiste breedte is ',
    deleteImage: 'Verwijder afbeelding',
    changeMobile: 'Wijzig mobiel',
    birthday: {
      day: 'dag',
      month: 'maand',
      year: 'jaar',
      months: {
        jan: 'januari',
        feb: 'februari',
        mar: 'maart',
        apr: 'april',
        may: 'mei',
        jun: 'juni',
        jul: 'juli',
        aug: 'augustus',
        sept: 'september',
        oct: 'oktober',
        nov: 'november',
        dec: 'december',
      },
    },
    bignumberTakenDescr: {
      buttonLabel: 'Er is iets fout gegaan',
      clickHere: 'klik hier',
      title: 'BIG-nummer al in gebruik in een ander account.',
      p1: 'Uw BIG-nummer is al geregistreerd met een ander email account. Een BIG-nummer kan maar aan 1 e-mail adres gekoppeld worden. We allow allow BIG-numbers to be registered once. Weet u zeker dat dit uw BIG-nummer is? Zoja doe dan het volgende:',
      li1: 'Heeft u een ander account, log dan in met dat account, u kunt via uw profiel uw e-mail wijzigen.',
      li2: 'Als u uw e-mail niet meer kunt herinneren, {button} om via de wachtwoord vergeten functie op basis van uw BIG-nummer een email te ontvangen.',
      li3: 'Heeft u geen toegang meer tot dit e-mailadres of ontvangt u geen email, neem dan contact met ons op via info@medischescholing.nl. Om uw identiteit te bevestigen zullen we u vragen om bewijs van uw BIG inschrijving. We kunnen dan uw e-mailadres voor u wijzigen.',
    },
    saveBigNumberNoVerification: 'BIG-number opslaan zonder verificatie',
    bignumberRemove: {
      deletePrompTitle: 'Verwijder beroep',
      deletePrompMsg: 'Weet u zeker dat u dit beroep wilt <b>verwijderen</b>?',
      deletePrompMsgMultiProfession:
        'Other professions connected to this bignumber, they also will be removed. <br> Are you sure you want to <b>remove</b> your profession?',
      deletePrompConfirmText: 'Verwijder beroep',
      deletePrompCancelText: 'Annuleren',
    },
    googleTwoFactorAuth: 'Google Two factor Authenticatie',
    twoFACodeInvalid: 'Code ongeldig',
    twoFAEnterCodeEnable: 'Voer code in om 2FA in te schakelen',
    twoFAEnterCodeDisable: 'Voer code in om 2FA uit te schakelen',
    confirmPasswordToContinue: 'Bevestig uw wachtwoord om door te gaan',
    twoFAEnable: 'Enable 2FA',
    twoFADisable: 'Disable 2FA',
    twoFACodePlaceholder: 'Google Authenticator code',
    twoFAQRModalApp: {
      authApp: 'Authenticator App',
      enterCode: 'Voer 6 cijferige code in',
      enterCodeToContinue: 'Please enter Google Authenticator code to continue',
      step1Label: 'Step 1: Download Google Authenticator App',
      step2Label:
        'Step 2: Open the app and add a new account and insert this key',
      step3Label: 'Step 3: Use Google Authenticator App for getting code',
      saveCodeHint:
        'Belangrijk! Schrijf de beveiligingscode ergens op en bewaar het op een veilige plek. U moet de beveiligingscode gebruiken om in te loggen wanneer u uw telefoon verliest.',
    },
    twoFALostHelper: {
      iLostMyCode: 'Ik ben mijn code kwijt',
      lostCode: 'QR code kwijt?',
      lostCodeHelper:
        'Ga naar de Google Authenticator App en maak een nieuw account aan met de beveiligingscode. Deze code heeft u gekregen bij het configureren van de 2 Factor Authenicatie.',
      noSafeCode: 'Geen beveiligingscode?',
    },
    selectBignumber: 'Selecteer BIG-nummer',
    chooseBigNumberFromList: 'Kies uit lijst',
    addNewBignumber: 'Nieuw BIG-nummer toevoegen',
    notifications: {
      email: 'Uw email is nog niet geverifieerd.',
      mobile:
        'Uw mobiele nummer is nog niet geverifieerd. Klik op de bevestig knop naast uw mobiele nummer.',
      profession:
        'Uw heeft geen beroep geselecteerd in uw profiel. Dit is noodzakelijk voor accreditatie.',
      account:
        'Uw profiel is niet compleet. Dit is belangrijk voor het ontvangen van accreditatie. Voer uw voornaam, achternaam en postcode in.',
    },
    function: 'Functie (geen medisch beroep)',
    addCompanies: 'Organisatie toevoegen',
    automaticEmails: 'Automatische emails',
    updateMailingSettingsConfirm: 'E-mail voorkeuren aanpassen',
    mainTabLabel: 'Profiel',
    preferencesTabLabel: 'Instellingen',
    language: 'Taal',
    youSubscribedTo: 'U bent ingeschreven voor',
    youUnsubscribedFrom: 'U bent uitgeschreven voor',
    rejectMarketingTitle:
      'Stuur me geen kortingen, nieuwsbrieven of andere aanbiedingen.',
    marketingMailingDisabledDescription:
      'U heeft marketing emails uitgeschakeld. ',
    updateRejectMarketingConfirm: 'Email voorkeuren aanpassen',
    rejectMarketingEmailsUpdated: 'Email voorkeuren aanpassen',
    allowMarketingEmails: 'marketing emails toestaan',
    deleteAccount: {
      sectionTitle: 'Verwijder account',
      buttonText: 'Verwijder',
      confirmTitle: 'Verwijder account',
      confirmSubtitle:
        'Hiermee wordt uw MedischeScholing account binnen 14 dagen verwijderd. Uw gevolgde cursussen en gegegevens voor accreditatie worden daarmee ook verwijderd. Om deze actie te bevestigen vragen we eenmalig uw wachtwoord.',
      confirmHawebSSOSubtitle:
        'Hiermee wordt uw MedischeScholing account binnen 14 dagen verwijderd. Om deze actie te bevestigen is er een code naar u gemaild.',
      confirmButton: 'Delete',
      sendingPincode: 'Pincode wordt verstuurd..',
    },
    subscribedCourses: 'Ingeschreven cursussen',
    favouriteCourses: 'Favoriete cursussen',
    recentlyViewedCourses: 'Recent bekeken cursussen',
    pincode: 'Pincode',
    profile: 'Profiel',
    settings: 'Instellingen',
    settingsFollowTitle: 'Organisaties die ik volg',
    settingsFollowText:
      'U ontvangt notificaties van nieuwe afleveringen van organisaties die u volgt.',
    settingsFavoritesTitle: 'Favoriete series',
    settingsFavoritesText:
      'U ontvangt notificaties voor nieuwe afleveringen van uw favoriete series.',
    settingsNotifications: 'Notificaties',
    account2FA: 'SCHAKELEN 2FA',
    personalization: 'Personalisatie',
    address: 'Adres',
    company: 'Organisatie',
    companyKVK: 'KVK-nummer',
    companyBTW: 'BTW-nummer',
    companyAGB: 'AGB-code',
    notificationsOffTitle: 'Kanaal notificaties uitgeschakend',
    notificationsOffSubTitle:
      'U ontvangt geen notificaties omdat uw telefoon dit blokkeerd. U kunt dit aanpassen in de instellingen van uw telefoon.',
    notificationsOffButton: 'Ga naar instellingen',
    privacyDeclaration: 'Privacyverklaring',
    termsAndConditions: 'Algemene voorwaarden',
  },
  notFound: {
    title:
      'Deze pagina kan geopend worden in de app. Klik op de link hieronder om de pagina in de browser te openen:',
    clickHere: 'Klik hier',
  },
  userProfile: {
    title: 'Profiel',
    wasCopied: 'Gekopieerd',
    email: 'Email',
    mobile: 'Mobiel',
    updated: 'Profiel bijgewerkt',
  },
  link: 'Koppeling',
  verifyProfile: {
    title: 'VERIFIEREN',
    subtitle:
      'We moeten een aantal dingen verifiëren voordat u door kunt gaan.',
    emailLabel: 'Email',
    postalCodeLabel: 'Postcode',
    postalCodePlaceholder: '1234 AB',
    postalCodeError:
      'Ongeldig format. Vul uw postcode in zonder spatie. Voorbeeld: 1234AB',
    mobile: {
      label: 'Mobiel nummer',
      smsLabel: 'Verstuur SMS-code',
      resendBtn: 'Opnieuw',
      invalidFormatMessage: 'Ongeldig format voor mobiel nummer',
      successOtpMessage: 'Voltooid',
      invalidOtpMessage: 'Ongeldige SMS-code',
      verify: 'Verifieer',
      otpFailed: 'Gefaald',
    },
    bigNumber: {
      label: 'Heeft u een BIG-nummer?',
      yes: 'Ja',
      no: 'Nee',
      title: 'BIG-nummer',
      verify: 'Bevestig BIG-nummer',
      enter: 'voer BIG-nummer',
      registration: 'Voer Registratienummer',
      answer: 'Beantwoord bovenstaande vraag',
    },
    profession: {
      label: 'Beroep',
      add: 'Beroep toevoegen',
      placeholder: 'Selecteer beroep',
    },
    registrationLabel: 'Registratienummer',
    skipConfirm: {
      message: 'Weet u zeker dat u de vertificatie wilt overslaan?',
      messageBody:
        'Deze gegevens zijn nodig voor het aanvragen van accreditatie. Is uw BIG-nummer al geregistreerd? Log dan uit en log opnieuw in met dat account of neem',
      contactUs: 'contact met ons op.',
    },
    verifyTooltipMessage: 'Kies eerst wel of geen bignummer.',
    selectBignumberMessage: 'Voer eerst uw BIG nummer in',
    selectedBignumberMessage:
      'Beroep is gekoppeld aan BIG-nummer. U kunt het aanpassen door uw BIG-nummer aan te passen.',
    addAnotherProfession: 'Mjin beroep is niet compleet',
    addNewProfession: 'Nieuw beroep',
    addEditProfessions: 'Toevoegen/bewerken beroep',
    noProfessions: 'Geen beroep',
    myProfessions: 'Mijn beroep(en)',
    oneProfessionRequired: 'Tenministe één beroep verplicht',
    yourProfession: 'Uw beroep',
    splitSelection: {
      title: 'Kies één van de volgende',
      subtitle:
        'Het beroep op basis van uw BIG-nummer komt automatisch het BIG-register. Niet alle beroepen staan volledig geregistreerd in het BIG-register. Bent u bijvoorbeeld in opleiding tot specialist of bent u profielarts dan kunnen we deze informatie niet uit het BIG-register halen. U kunt via de knop hieronder uw beroep aanvullen.',
      addAddition: 'BIG beroep aanvullen',
      addAdditionHint:
        'Als uw beroep niet in deze lijst staat dan is het geen toevoeging op uw BIG-geregistreerde beroep. U kunt elk ander medisch beroep handmatig toevoegen door dit venster te sluiten en via de knop “Een beroep toevoegen.',
      addAnotherBig: 'BIG-nummer toevoegen',
      addNonBig: 'Non BIG beroep toevoegen',
    },
  },
  home: {
    featured: 'Uitgelicht',
    new: 'Nieuwe kanalen',
    recently: 'Recent afgespeeld',
    continueWatching: 'Verder luisteren',
    goodMorning: 'Goedemorgen',
    goodAfternoon: 'Goedemiddag',
    goodNight: 'Goedenavond',
    view: 'bekijk alles',
    following: 'Volgend',
    ownPodcast: 'Zelf een podcast maken?',
    topStreamTitle: 'Top 10 podcasts',
    topCoursesTitle: 'Populairste kanalen',
    newPodcastReleases: 'Nieuwste podcasts',
    recommendedStreams: 'Aanbevolen',
  },
  podcast: {
    played: 'Afgespeeld',
    minLeft: 'min resterend',
    left: 'resterend',
    seasons: 'seizoenen',
    episodes: 'afleveringen',
    min: 'min',
    favorites: 'favorieten',
    series: 'serie',
    readMore: 'Lees meer',
    readLess: 'Lees minder',
    allEpisodes: 'Alle afleveringen',
    recently: 'Recent gespeeld',
    local: 'nl-Nl',
    noPermission:
      'Deze podcast is alleen te beluisteren voor geregistreerde gebruikers. Registreer of log in met uw MedischeScholing account. U kunt via de browser luisteren maar ook de app downloaden.',
    noStreams: 'Geen streams',
    information: 'Informatie',
  },
  player: {
    chapters: 'Hoofdstukken',
    audioNotAvailableAlert: 'Sorry, this audio is not available',
  },
  versionAlert: {
    title: 'Nieuwe versie is beschikbaar',
    hardUpdateSubtitle: 'Werk de app bij om door te gaan',
    description: 'Er is een nieuwe versie beschikbaar. U kunt deze nu updaten.',
  },
  library: {
    library: 'BIBLIOTHEEK',
    new: 'NIEUW',
    accreditation: 'ACCREDITATION',
    accreditationPlaceholder: "You don't have completed accreditation streams.",
  },
  following: {
    following: 'VOLGEND',
    favorites: 'FAVORIETEN',
    follow: 'VOLGEN',
    unfollow: 'ONTVOLGEN',
    followPlaceholder:
      'U volgt op dit moment geen organisaties. Door organisaties te volgen kunt u notificaties ontvangen wanneer er nieuwe afleveringen worden geupliceerd.',
    favoritePlaceholder:
      'U heeft op dit moment geen favoriete podcasts of series. U kunt hier zowel losse podcasts als series gemakkelijk opslaan om later terug te vinden. Als er nieuwe afleveringen worden gepuliceerd kunt u een notificatie ontvangen.',
    followingOrganisations: 'Jouw organisaties',
    followingSeries: 'Je favoriete series',
    followingEpisods: 'Gelikete afleveringen',
    noSeries: 'Geen favoriete series',
    noEpisodes: 'Geen gelikete afleveringen',
    noFollowing: 'Geen gevolgde organisaties',
  },
  shareModal: {
    share: 'Deel podcast',
    share_text: 'Delen',
    share_link: 'Deel link',
    more_options: 'Meer opties',
    share_title: 'Controleer uw podcast',
    email_subject: 'Deze podcast is misschien iets voor jou.',
  },
  savedCourses: {
    noSavedCourses: 'U hebt geen opgeslagen cursussen',
    subscribe: 'Inschrijven',
    removeCourse: 'Verwijderen',
    successDialogTitle: 'Opgeslagen',
    courseSavedMessage: {
      episode: 'Deze aflevering is opgeslagen bij je favorieten',
      series: 'Deze serie is opgeslagen bij uw favorieten',
    },
    dialogConfirmText: 'Oké',
    dialogCancelText: 'Annuleren',
    dialogPromptMessage: {
      episode:
        'Weet u zeker dat u deze aflevering wilt verwijderen uit uw favorieten?',
      series:
        'Weet u zeker dat u deze series wilt verwijderen uit uw favorieten?',
    },
    dialogPromptTitle: {
      episode: 'Verwijder favoriete aflevering',
      series: 'Verwijder favoriete series',
    },
    loginToSaveCourse: 'Log in om cursussen op te slaan onder uw favorieten',
    addToFavourites: 'Toevoegen aan favorieten',
    removeFromFavourites: 'Verwijderen uit favorieten',
  },
  followCompanies: {
    followingCompany: 'Organsaties die ik volg',
    successfullyFollowed: 'U volgt nu deze organisatie',
    removedFromFollowed: 'Volgen verwijderd',
    deletePromptTitle: 'Organisatie niet meer volgen',
    deletePromptMessage:
      'Weet je zeker dat je wil <b>ontvolgen</b> dit bedrijf?',
    deletePromptConfirmText: 'Ja',
    deletePromptCancel: 'Annuleren',
  },
  searchPage: {
    startSearch: 'Laten we iets interessants vinden!',
    noResults: 'Niets gevonden',
    myCollection: 'Mijn resultaten',
    results: 'Resultaten',
  },
  inviteToken: {
    title:
      'Deze app is alleen toegankelijk op uitnodiging, Voer hier uw code in',
    token: 'CODE',
    error: 'Code is niet geldig',
  },
  pushNotifications: {
    describe: {
      title: 'Beste collega',
      message:
        'Als u podcast kanalen gaat volgen sturen we graag een notificatie zodra er een nieuwe aflevering gepubliceerd is. Hiervoor moet uw telefoon notificaties toestaan.',
      actionYes: 'Toestaan',
      actionNo: 'Weigeren',
    },
    disabledPrompt: {
      title: 'Notificaties geblokkeerd',
      message:
        'Uw telefoon blokkeert alle notificaties. Hierdoor kunt u geen updates ontvangen voor nieuwe podcasts. Ga naar de instellingen van uw telefoon om de meldingen toe te staan.',
      actionYes: 'Ga naar instellingen',
      actionNo: 'Negeren',
    },
  },
  forgotPassword: {
    title: 'Wachtwoord vergeten',
    subTitle:
      'Vul uw hier uw email in en u ontvangt een link om uw wachtwoord opnieuw in te stellen. Weet u uw email niet meer maar wel uw BIG-nummer, vul dan uw BIG-nummer in en we sturen de link naar het email dat gekoppeld is aan uw BIG-nummer.',
    usernameLabel: 'E-mail / BIG-nummer',
    invalidEmailText: 'Ongeldig e-mailadres',
    usernameSuccessMessage:
      'Wij hebben u een link gestuurd waarmee u een nieuw wachtwoord kunt instellen',
    submitBtn: 'Aanvragen',
    backToLoginLink: 'Terug naar inlogscherm',
    forgotEmail: 'Email vergeten',
  },
  forgotEmail: {
    title: 'Email vergeten',
    subTitle:
      'Als u uw email niet meer weet maar u heeft wel uw BIG-nummer ingevoerd, dan kunt u alsnog een wachtwoord reset link ontvangen. Deze link wordt gestuurd naar het email wat aan uw BIG-nummer gekoppeld is. Heeft u geen toegang meer tot dat email of weet u niet meer wat dat email adres is. Neem dan contact met ons up. Ter bevestiging van uw identiteit vragen wij om een kopie van uw RGS registratie. Deze kopie wordt daarna weer verwijderd.',
    usernameLabel: 'BIG-nummer',
    invalidEmailText: 'BIG-nummer ongeldig',
    forgotBigNumber: 'Wachtwoord BIG-nummer',
  },
  invitationCard: {
    title: 'Collega uitnodigen',
    text: "Medicast is gratis voor zorgverleners maar alleen toegankelijk met een uitnodigingscode. Je kunt met de knop hieronder gemakkelijk een uitnodigingscode naar collega's sturen.",
    button: 'UITNODIGEN',
    shareText:
      "Misschien is Medicast iets voor jou. Hier heb je een uitnodigingscode: {token}. Installeer eerst de app via: https://app.medicast.nl/invite/{token} of zoek op 'Medicast' in de app store van android of ios.",
    shareInvite: 'Collega uitnodigen',
    inviteCode: 'Uw toegangscode',
    downloadApp: 'Download de Medicast app',
    successMessage: 'De code moet u plakken na het installeren van de app.',
    installedApp: 'U heeft Medicast al geinstalleerd.',
  },
  companyPage: {
    about: 'Over ons',
    popular: 'Populair',
    new: 'Nieuw',
    series: 'Kanalen',
  },
  accreditation: {
    points: 'Punten',
    point: 'Punt',
    noProfessions:
      'U heeft geen beroep in uw profiel ingevuld. We kunnen daarom niet aangeven op deze podcast voor u geaccrediteerd is. Ga naar uw profiel om uw beroep in te vullen.',
    completeProfile: 'Naar profiel',
    noAccreditation:
      'Deze cursus is niet geaccrediteerd voor het beroep wat bekend is in uw profiel.',
    moreInfo: 'Meer informatie',
    accreditation: 'Accreditatie',
    requirements: 'CLICK HIER VOOR ACCREDITATIE',
    steps:
      'U dient de volgende 3 stappen te voltooien om in aanmerking te komen voor accreditatie.  Uw accreditatie wordt binnen 14 dagen verwerkt. Heeft u vragen neem dan contact met ons op.',
    verifyBtn: 'CONTROLEER UW GEGEVENS',
    checkDetails:
      'We hebben persoonlijke informatie van u nodig voordat we uw accreditatie verzoek kunnen verwerken. Controleer de gegevens hieronder vanuit uw profiel.',
    request: 'ACCREDITATIE AAVRAGEN',
    verifyBtnConfirmed: 'MIJN GEGEVENS',
    examBtn: 'EXAMEN VRAGEN BEANTWOORDEN',
    examBtn2: 'TOETS',
    examBtnPassed: 'TOETS GESLAAGD',
    examBtnFailed: 'TOETS NIET GESLAAGD',
    reviewBtn: 'EVALUATIE INVULLEN',
    reviewBtn2: 'EVALUATIE',
    reviewBtnPassed: 'EVALUATIE COMPLEET',
    pageHeader: 'ACCREDITATIE VRAGEN',
    open: 'OPEN',
    completed: 'AFGEROND',
    show: 'TOON',
    hide: 'VERBERG',
    correctAnswer: 'CORRECT ANTWOORD',
    wrongAnswer: 'FOUT ANTWOORD',
    sentAnswer: 'VERSTUURD ANTWOORD',
    answerSent: 'ANTWOORD VERSTUURD',
    answerResend: 'FOUT BEANTWOORD DRUK HIER OM TE HERKANSEN',
    questionAnswered: 'vragen beantwoord',
    verifyPlaceholder:
      'Controleer eerst de gegevens van uw accreditatie aanvraag.',
    congratulationPlaceholder:
      'Gefeliciteerd, u bent geslaagd. Uw accreditatie aanvraag is hiermee afgerond.',
    openTabPlaceholder:
      'U heeft op alle vragen geantwoord. Je kunt teruggaan naar de podcast.',
    completedTabPlaceholder: 'Hier zie je alle vragen die je hebt beantwoord.',
    errorData: 'Er is iets misgegaan, we kunnen de vraaggegevens niet laden',
    timeLeft: 'Sluit over ',
    goBack: 'GA TERUG NAAR VRAAG',
    missedQuestionAlert:
      'Let op! U heeft een vraag gemist. Druk op ga terug om de vraag opnieuw voorbij te zien komen.',
    noQuestions:
      "OPGELET: Klik deze pagina niet weg. Hier verschijnen de tussentijdse vragen en posttoets. Indien u het scherm vergrendeld tijdens het luisteren hoort u een 'ping' zodra er een tussentijdse vraag in het beeld verschijnt. De tussentijdse vragen moet u op tijd beantwoord hebben om recht te hebben op accreditatie. Veel luisterplezier gewenst!",
    skippedTrack:
      'Let op! U heeft een deel van de podcast niet geluisterd. Hierdoor kunt u niet in aanmerking komen voor accreditatie. ',
    emailCertificate: 'Email Certificate',
    clickOnProgressBar:
      'Klik op de voortgangsbalk om naar de eerste onbeantwoorde vraag te scrollen',
  },
  streams: {
    livestreams: 'Streams',
    streamIsLiveLabel: 'Stream is live',
    create: 'Aanmaken',
    platform: 'Streaming platform',
    embedUrl: 'Embed URL',
    selectPlatform: 'Selecteer een platform',
    selectEpisode: 'Selecteer een episode',
    rssUrl: 'RSS URL',
    noEpisodes: 'Geen episodes',
    chatRoom: 'Chat',
    allowFullscreen: 'Fullscreen toestaan',
    discussionRoom: 'Discussie',
    QandA: 'Q&A',
    playerSettings: 'Player instellingen',
    images: 'Afbeeldingen',
    selectImage: 'Selecteer afbeelding',
    required: 'Verplicht',
    selectForm: 'Select vragenlijst',
    forms: 'Vragenlijsten',
    documents: 'Documenten',
    noDocuments: 'Geen documenten',
    selectDocument: 'Selecteer document',
    course: 'Cursus',
    views: 'Bekeken',
    subscribed: 'Ingeschreven',
    questions: 'Vragen',
    date: 'Datum',
    published: 'Gepubliceerd',
    created: 'Aangemaakt',
    edited: 'Bewerkt',
    points: 'Punten',
    analytics: 'Analytics',
    createStream: 'Nieuw',
    editStream: 'Bewerken',
    courseTitle: 'Cursus titel',
    console: 'Admin console',
    remove: 'Verwijder',
    unpublish: 'Publicatie ongedaan maken',
    view: 'Bekijk',
    settings: 'Instellingen',
    goLive: 'Ga live',
    addTags: 'Tag toevoegen',
    addThisTag: 'Deze tag toevoegen',
    selectLabel: 'Druk op enter om te selecteren',
    deselectLabel: 'Druk op enter om te verwijderen',
    typeComment: 'Schrijf uw bericht',
    title: 'Titel',
    discussion: 'Discussie',
    disableDiscussion: 'Discussie uitschakelen',
    sortBy: 'Sorteer op',
    disableChat: 'Chat uitschakelen',
    chat: 'Chat',
    showMore: 'Toon meer',
    showLess: 'Toon minder',
    QnA: 'Q&A',
    exam: 'Toets',
    poll: 'Poll',
    review: 'Review',
    vote: 'Stem',
    disableQnA: 'Q&A uitschakelen',
    time: 'Tijd',
    live: 'Live',
    stream: 'Stream',
    typeATitle: 'Titel',
    typeAMessage: 'Bericht',
    sortByUpVoted: 'Upvotes',
    sortByDownVoted: 'Downvotes',
    sortByRecent: 'Meest recent',
    sortByLast: 'Minst recent',
    blockUser: 'Blokkeer gebruiker',
    messageUser: 'Stuur bericht naar gebruiker',
    reply: 'Beantwoorden',
    report: 'Melden',
    message: 'Bericht',
    questionnaire: 'Vragenlijst',
    disableQuestionnaire: 'Vragenlijsten uitschakelen',
    hideAllQuestions: 'Verberg alle vragen',
    showAllQuestions: 'Toon alle vragen',
    hideAllResults: 'Verberg alle resultaten',
    showAllResults: 'Toon alle resultaten',
    completed: 'Voltooid',
    passed: 'Geslaagd',
    displayMoments: 'Scherm momenten',
    successDisplayOptionsSave: 'Momenten opgeslagen',
    chooseDisplayType: 'Kies uw scherm moment',
    start: 'Begin',
    end: 'Eind',
    clockTime: 'Klok tijd',
    playerTime: 'Video tijd',
    responded: 'Reacties',
    results: 'Resultaten',
    displayTimes: 'Scherm momenten',
    schedule: 'Schema',
    createSchedule: 'Schema aanmaken',
    video: 'Video',
    successfullySaved: 'Opgeslagen',
    savedStream: 'Opgeslagen',
    deletePromptTitle: 'Verwijder',
    deletePromptMessage:
      'Weet u zeker dat u deze stream wilt <strong>verwijderen</strong>?',
    deletePromptConfirmText: 'Ja',
    deletePromptCancel: 'Annuleren',
    deletedStream: 'Verwijderd',
    updatedStream: 'Bijgewerkt',
    unPublish: 'Publicatie ongedaan maken',
    publish: 'Publiceren',
    selectImageType: 'Soort',
    selectDocumentType: 'Soort',
    noImages: 'Geen afbeeldingen',
    newQuestionText: 'Een nieuwe vraag wordt getoond',
    ok: 'Ok',
    sent: 'Verstuurd',
    send: 'Verstuur',
    correct: 'Antwoord correct',
    wrong: 'Antwoord niet correct',
    retry: 'Klik hier om te herkansen,',
    newQuestionAppears: 'Een nieuwe vraag wordt getoond',
    streamToolbar: 'Stream menu',
    speakers: 'Sprekers',
    program: 'Tijdschema',
    info: 'Infomatie',
    slides: 'Presentatie',
    messageCreated: 'Bericht aangemaakt',
    errorCreatingMessage: 'Fout bij aanmaken van bericht',
    deleteMessageConfirmation:
      'Weet u zeker dat u dit bericht wilt verwijderen?',
    leaveStreamConfirmation: 'Weet u zeker dat u deze stream wilt beeindigen?',
    display: 'Display',
    help: 'Help',
    leaveEvent: 'Verlaten',
    answerRequired: 'Antwoord verplicht',
    createDisplayMoment: 'Aanmaken scherm moment',
    notAvailable: 'Stream is  niet beschikbaar',
    endEvent: 'Event beeindigen',
    survey: 'Enquete',
    endEventPromptTitle: 'Event verlaten',
    endEventPromptMessage:
      'Weet u zeker dat u dit event wilt verlaten, hiermee gaan alle gebruikers terug naar de wachtkamer. Ze kunnen geen chatberichten meer sturen of vragen beantwoorden.',
    refresh: 'View vernieuwen',
    refreshInterval: 'Vernieuw in (sec)',
    enterNumber: 'Enter number',
    refreshButton: 'Vernieuwen',
    successRefresh: 'Vernieuwen geslaagd',
    cantLoadData:
      'Er gaat iets mis, probeer het over enkele minuten opnieuw. Onze excuses voor het ongemak. Blijft u problemen houden, mail naar info@medischescholing.nl',
    pusherError: {
      mainError:
        'General socket verbinding gefaald. Vernieuw de pagina of gebruik een andere browser',
      publishError:
        'Publish socket verbinding gefaald. Vernieuw de pagina of gebruik een andere browser',
      chatError:
        'Chat socket verbinding gefaald. Vernieuw de pagina of gebruik een andere browser',
      qnaError:
        'QenA socket verbinding gefaald. Vernieuw de pagina of gebruik een andere browser',
    },
    redirectingToCourse:
      'Dit account heeft geen toegang tot de stream, u moet zich eerst inschrijven.',
    questionnaireChanged:
      'Accreditatievragen zijn veranderd, de score van gebruikers kan hiermee veranderen.',
    myProgram: 'Mijn programma',
    linkPlatformButtonText: 'Ga naar sessie',
    unknownName: 'Naam onbekend',
    unknownProfession: 'Beroep onbekend',
    nameAndProfessionRequired:
      'Naam en beroep zijn verplicht voor het versturen van berichten, ga naar uw profiel om dit aan te vullen.',
    tabsPlaceholders: {
      chatViewOnly:
        'Hier kunnen alle deelnemers met elkaar chatten. Anonieme accounts kunnen niet deelnemen aan de chat.',
      chatCantView: 'Uw account heeft geen rechten om de chat te lezen',
      chatNoProfileLink:
        'Hier kunnen alle deelnemers met elkaar chatten. Op dit moment zijn er nog geen chatberichten verzonden.',
      chat: 'Hier kunnen alle deelnemers met elkaar chatten. Op dit moment zijn er nog geen chatberichten verzonden. Voordat u een chatbericht kunt plaatsen moet uw {0} bijgewerkt zijn. Op uw profielpagina kunt u ook uw profielfoto wijzigen.',
      qna: 'Hier kunt u direct vragen stellen aan de moderatoren van de stream. De vragen en antwoorden zijn alleen zichtbaar voor u en de moderatoren.',
      exam: 'Tijdens de stream zullen hier automatisch de accreditatie vragen verschijnen. U ziet een pop-up bericht dat u een vraag moet beantwoorden. Voordat u een antwoord kan versturen moet u uw profiel verifiëren voor de accreditatie.',
      poll: 'Tijdens de stream zullen hier automatisch pollvragen verschijnen. Deze vragen hebben geen invloed op uw accreditatie.',
      vote: 'Tijdens de stream kunt u hier stemmen. De resultaten worden gedeeld met de administratoren. Stemmen is niet anoniem.',
      chatGuestCantCreate:
        'Anonieme accounts kunnen niet deelnemen aan de chat.\n' +
        '                  Wilt u alsnog deelnemen in de chat, log dan in met een bestaand account of maak een nieuwe account aan door te registreren. U moet zich dan opnieuw inschrijven voor deze cursus. Dit kan via de inschrijfpagina of via de link die u heeft ontvangen. Ook moet uw profiel ingevuld zijn.\n' +
        '                  Let op! Uw BIG-nummer kan maar onder één account geregistreerd worden. Heeft u in het verleden al geregisteerd, gebruik dan dat account. U kunt het e-mailadres altijd aanpassen in uw profiel. \n' +
        '                  Heeft u vragen, neem dan contact met ons op via info@medischescholing.nl.',
    },
    administrator: 'Beheerder',
    pin: {
      pin: 'Vastzetten',
      pinOff: 'Losmaken',
      pinned: 'Vastgezet',
      unpinned: 'Losgemaakt',
      pinConfirm:
        'Weet u zeker dat u dit bericht wilt <strong>vastzetten</strong>?',
      pinOffConfirm:
        'Weet u zeker dat u dit bericht wilt <strong>losmaken</strong>?',
    },
    adminChanges: {
      displayMoments: 'Toon moment is veranderd door moderator',
      settings: 'Stream instellingen zijn veranderd door moderator',
      liveState: 'Stream instelling LIVE/END EVENT is veranderd door moderator',
      publishState: 'Publiceren is veranderd door moderator.',
      pinnedMessage: 'Vastgezette berichten lijst is veranderd door moderator',
    },
    examPassedPopUpCertificate:
      'Gefeliciteerd, uw bent geslaagd voor de accreditatie-toets. U kunt uw certificaat downloaden via de knop Certificaat. U kunt uw certificaat altijd terugvinden via de Ingeschreven Cursussen pagina. Als u een anonieme toegang kunt u dit certificaat niet later terugvinden.',
    examPassedPopUp:
      'Gefeliciteerd, uw bent geslaagd voor de accreditatie-toets.',
    passedLowerCase: 'Geslaagd',
    notPassedLowerCase: 'Niet geslaagd',
    openProgram: 'Open programma',
    closeProgram: 'Sluit programma',
    professionValidationReasons: {
      table: {
        title: 'Sommige beroepen zijn niet geaccrediteerd voor deze cursus.',
        profession: 'Beroepen',
        reason: 'Reden',
        valid: 'Geldig',
      },
      title: 'Accreditatie alert',
      noAccreditationForProfession:
        'Cursus is niet geaccrediteerd voor dit beroep',
      noAccreditationForCourse:
        'Deze cursus komt niet in aanmerking voor accreditatie.',
      accreditationPossible: 'Cursus is geaccrediteerd voor dit beroep',
    },
    leaveAsGuestPrompt: {
      title: 'Verlaat als gast',
      message:
        'Als uw bent geslaagd, download dan het certificaat, u kunt deze niet later terugvinden.',
      confirmText: 'Verlaat en login',
      cancelText: 'Annuleer',
    },
    downloadCertificate: 'Download certificaat',
    displayMomentsModal: {
      type: 'Type',
      action: 'Actie',
      showQuestion: 'Toon vraag',
      publishResults: 'Publiceer resultaten',
      publishAnswers: 'Publiceer antwoord',
      hideQuestion: 'Verberg vraag',
      unPublishResults: 'Depubliceer resultaten',
      unPublishAnswers: 'Publiceer antwoorden',
      noCorrectAnswer: 'Geen juist antwoord',
      hideDirectAnswer: 'Verberg direct antwoord',
      attemptsAllowed: 'Pogingen toegestaan',
    },
    confirmProfileChangeEmailTooltip:
      'Dit e-mail hoort bij uw account. Ga naar uw profiel om het te wijzigen.',
    messageMovedToQnA: 'Dit bericht wordt beantwoord via Q&A.',
    receivedQnAMessage: 'U heeft een persoonlijk bericht ontvangen via de Q&A',
    qnaMoveOptions: {
      title: 'Verplaats bericht naar Q&A',
      label:
        'De gebruiker ontvangt een persoonlijk bericht via de Q&A. Wat wilt u met het chat bericht doen?',
      option1:
        'Vervang originele bericht met “Dit bericht is beantwoord via de Q&A.“',
      option2: 'Bericht is verwijderd.',
      option3: 'Behoud chat bericht.',
    },
    chatActions: {
      answer: 'Beantwoord',
      answerInQnA: 'Beantwoord in Q&A',
      successfullyMoved: 'Bericht verplaatst',
    },
    linkInUrlWarning:
      'Het versturen van links is alleen voor moderators toegestaan.',
    Views: 'Views',
    liveViews: 'Live',
    autoRefreshViews: 'Vernieuw telling',
    showTotalLiveViews: 'Toon live views',
    showTotalViews: 'Toon views',
    showTotalSubscribers: 'Toon inschrijvingen',
    chapters: {
      chapters: 'Hoofdstukken',
      time: 'Tiid',
      title: 'Titel',
      addManually: 'Handmatig toevoegen',
      addFromStream: 'Toevoegen vanuit stream',
      noChapters: 'Geen hoofdstukken',
      play: 'Afspelen',
      deletePromptTitle: 'Verwijder hoofdstuk',
      deletePromptMessage:
        'Weet u zeker dat u dit hoofdstuk wilt <b>verwijderen</b> ?',
      deletePromptConfirmText: 'Verwijder',
      deletePromptCancelText: 'Annuleer',
      chaptersWarningTitle: 'Chapters time references',
      chaptersWarningMessage:
        'Warning, you are about to change the stream url. In doing this the chapter time references might not match anymore. Would you like to proceed:',
      chaptersWarningConfirm: 'Yes - proceed',
    },
    notPublished: 'Stream niet gepubliceerd',
    rememberProgress: 'Onthoud voortgang',
    noMessages: 'Geen berichten',
    markAsUnread: 'Markeer als ongelezen',
    hideRead: 'Verberg gelezen',
    allowCertificate: 'Download certificaat toestaan',
    leaveWithUnansweredQuestions:
      'U staat op het punt om deze stream te verlaten maar er zijn nog vragen onbeantwoord.',
    completeQuestions: 'Beantwoord vragen',
    emailUnreadMessage: 'Email ongelezen vragen',
    emailUnreadMessageTo: 'Email ongelezen vragen naar',
    streamDocuments: {
      presentation: 'Presentatie',
      handout: 'Handout',
      document: 'Document',
      references: 'Referenties',
    },
    streamAudio: {
      currentFiles: 'Huidige bestanden:',
      deletePromptTitle: 'Verwijderen',
      deletePromptMessage:
        'Weet u zeker dat u deze nieuwsbrief wilt <b>verwijderen</b>?',
    },
    retryForm: 'Herkansen',
  },
  examLeaveModal: {
    successTitle: 'Gefeliciteerd',
    successSubTitle: 'U bent geslaagd voor de toets',
    failTitle: 'Het spijt ons, u bent niet geslaagd voor de toets.',
    failSubTitle:
      'U kunt geen accreditatieverzoek indienen. Bekijk de video opnieuw en beantwoord de vragen opnieuw. Heeft u vragen, neem dan contact met ons op via info@medischescholing.nl',
    failSubTitleNoRetries:
      'U kunt geen accreditatieverzoek indienen. Heeft u vragen, neem dan contact met ons op via info@medischescholing.nl',
    correct: 'Correct',
    wrong: 'Fout',
    unAnswered: 'Niet beantwoord',
    viewResults: 'Resultaten',
    leave: 'Afsluiten',
    leaveExam: 'Terug',
    cancel: 'Annuleren',
    questionAnswered: 'Vraag beantwoord',
    emailCertificate: 'Email certificaat',
  },
  verifyEmail: {
    title: 'E-mailadres verifiëren',
    subtitle:
      'We moeten een aantal dingen verifiëren voordat u door kunt gaan.',
    errorMessage: 'Er ging iets mis bij de verificatie van uw e-mailadres',
    tooManyRequest: 'Te veel request, probeer het later opnieuw',
    pleaseLogin: 'Log alsjeblieft in',
    linkExpired:
      ' naar uw profiel en klik op de knop "Verificatie-e-mail opnieuw verzenden". U ontvangt een nieuwe e-mail om uw e-mail te verifiëren.',
    redirectToProfile: ' Naar uw profiel.',
  },
  onboardingTooltips: {
    followCompanyBtn: 'Volg de organisatie om updates te ontvangen.',
    favoriteCourseBtn: 'Bewaar deze podcast in uw lijst met favorieten.',
  },
};
