import { toastController } from '@ionic/vue';

export default () => {
  const defaultConfig = {
    position: 'top',
    duration: 2000,
  };
  const openErrorToast = async (axiosError) => {
    const errors = axiosError?.response?.data?.errors;

    if (!errors) {
      return;
    }

    const isString = typeof errors === 'string' || errors instanceof String;
    const isArray = Array.isArray(errors);
    const isObject = (typeof errors === 'object') & !isArray && errors !== null;

    let messages = '';

    if (isString) {
      messages = errors;
    } else if (isArray) {
      messages = errors.join('\n');
    } else if (isObject) {
      Object.keys(errors).forEach((key) => {
        errors[key].forEach((index) => {
          messages += index + '<br/>';
        });
      });
    } else {
      messages = 'Error';
    }

    const toast = await toastController.create({
      message: messages,
      position: 'top',
      color: 'danger',
      duration: 2000,
    });
    return toast.present();
  };
  const openSuccessToast = async (message) => {
    const toast = await toastController.create({
      message: message,
      position: 'top',
      color: 'success',
      duration: 2000,
    });
    return toast.present();
  };
  const openWarnToast = async (message) => {
    const toast = await toastController.create({
      message: message,
      position: 'top',
      color: 'danger',
      duration: 2000,
    });
    return toast.present();
  };

  const openToast = async (config) => {
    const toast = await toastController.create({
      ...defaultConfig,
      ...config,
    });
    return toast.present();
  };

  return { openErrorToast, openWarnToast, openSuccessToast, openToast };
};
