<template>
  <ion-searchbar
    ref="searchbarRef"
    class="search-bar"
    :value="modelValue"
    :placeholder="$t('commonKeys.search')"
    color="medium"
    :debounce="1500"
    @ionChange="onChange"
  />
</template>

<script setup>
import { defineProps, defineEmits, defineExpose, ref } from 'vue';
import { IonSearchbar } from '@ionic/vue';

const props = defineProps({
  modelValue: {
    type: String,
    required: true,
  },
});

const emit = defineEmits(['update:modelValue']);

const onChange = (e) => {
  emit('update:modelValue', e.target.value);
};

const searchbarRef = ref(null);
function focusInput () {
  setTimeout(() => {
    searchbarRef.value.$el.setFocus();
  }, 100);
}

defineExpose({
  focusInput,
});
</script>

<style scoped>
.search-bar{
  width: 600px;
  height: 40px;
}
</style>
