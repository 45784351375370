<template>
  <ion-page>
    <ion-content class="ion-padding-start ion-padding-end mt-8">
      <div
        v-if="loading"
        class="is-flex ion-justify-content-center ion-align-items-center "
      >
        <IonSpinner />
      </div>

      <div
        v-if="!loading && !searchInput"
        class="h-100 is-flex ion-justify-content-center ion-align-items-center"
      >
        {{ $t('searchPage.startSearch') }}
      </div>

      <div
        v-if="!loading && searchInput && !searchCourses.length"
        class="h-100 is-flex ion-justify-content-center ion-align-items-center"
      >
        {{ $t('searchPage.noResults') }} «{{ searchInput }}»
      </div>

      <section class="tablet-centered mt-large">
        <h2 v-if="filteredCourses.length || filteredStreams.length">
          {{ $t('searchPage.results') }}
        </h2>

        <SeriesPodcast
          v-for="(course, index) in filteredCourses"
          :key="`course-filtered-${index}`"
          :course-data="course"
          :is-buttons="false"
          :is-logo="true"
          @click="$router.push(`/course/${course.id}`)"
        />

        <SinglePodcast
          v-for="(stream, index) in filteredStreams"
          :key="`stream-filtered-${index}`"
          :podcast-data="stream"
          :course-media="getCourseMedia(stream)"
          @click="openPodcast(stream)"
        />
      </section>


      <section class="tablet-centered mt-large">
        <h2
          v-if="userCollectionCourses.length || userCollectionStream.length"
          class="mt-6"
        > {{ $t('searchPage.myCollection') }} </h2>

        <SeriesPodcast
          v-for="(course, index) in userCollectionCourses"
          :key="`course-series-${index}`"
          :course-data="course"
          @click="$router.push(`/course/${course.id}`)"
        />

        <SinglePodcast
          v-for="(stream, index2) in userCollectionStream"
          :key="`stream-${index2}`"
          :podcast-data="stream"
          :course-media="getCourseMedia(stream)"
          @click="openPodcast(stream)"
        />
      </section>
    </ion-content>
  </ion-page>
</template>

<script>
import { ref, computed } from '@vue/reactivity';

import {
  IonContent,
  IonPage,
  IonToolbar,
  IonBackButton,
  IonSearchbar,
  IonHeader,
  IonCard,
  IonCardHeader,
  IonCardSubtitle,
  IonButtons,
  IonCardTitle,
  IonCardContent,
  IonSpinner,
} from '@ionic/vue';
import {useStore} from "vuex";

import SeriesPodcast from '@/components/common/SeriesPodcast';
import SinglePodcast from '@/components/common/SinglePodcast';


export default {
  name: 'SearchPage',
  components: {
    IonPage,
    IonCard,
    IonHeader,
    IonContent,
    IonButtons,
    IonSpinner,
    IonToolbar,
    IonCardTitle,
    IonSearchbar,
    IonBackButton,
    IonCardHeader,
    IonCardContent,
    IonCardSubtitle,
    SeriesPodcast,
    SinglePodcast,
  },
  props: {
    searchInput: {
      type: String,
      default: '',
    },
  },

  setup () {
    const store = useStore();

    const loading = ref(false);

    const searchCourses = computed(() => store.getters['search/getSearchCourses']);
    const userCollectionCourses = computed(() => store.getters['search/getUserCollectionCourses']);
    const userCollectionStream = computed(() => store.getters['search/getUserCollectionStreams']);
    const filteredCourses = computed(() => store.getters['search/getFilteredCourses']);
    const filteredStreams = computed(() => store.getters['search/getFilteredStreams']);


    const openPlayer = (stream, course) => {
      const startAt = course.streams.findIndex(companyStream => companyStream.id === stream.id);
      store.dispatch('player/openPlayer', {
        course,
        company: course.company,
        playlist: course.streams,
        startAt,
      });
    };

    const openPodcast = (stream) => {
      store.dispatch('course/setCourseData', stream.course_id)
        .then((course) => {
          openPlayer(stream, course);
        });
    };

    const getCourseMedia = (stream) => {
      const rawFavoritesList = store.state.search.courses ?? [];
      return rawFavoritesList.find(course => course.id === stream.course_id)?.media ?? [];
    };

    return {
      loading,

      searchCourses,
      filteredStreams,
      filteredCourses,
      userCollectionCourses,
      userCollectionStream,

      openPodcast,
      getCourseMedia,
    };
  },
};
</script>

<style scoped>
  ion-card {
    -webkit-box-shadow: 0px 0px 10px 4px rgba(255, 255, 255, 0.6);
    -moz-box-shadow: 0px 0px 10px 4px rgba(255, 255, 255, 0.6);
    box-shadow: 0px 0px 10px 4px rgba(255, 255, 255, 0.6);
  }
  .mt-large{
    margin-top: 70px !important;
  }
</style>
