<template>
  <ion-page>
    <ion-tabs>
      <ion-router-outlet />
      <ion-tab-bar slot="bottom">
        <ion-tab-button
          v-for="(tab, index) in tabs"
          :key="`${tab.tab}-${index}`"
          :tab="tab.tab"
          :href="tab.href"
          @click="trackHome(tab.tab)"
        >
          <ion-icon :icon="tab.icon" />
          <ion-label>{{ tab.label }}</ion-label>
        </ion-tab-button>
      </ion-tab-bar>
    </ion-tabs>
  </ion-page>
</template>

<script>
import {
  IonTabBar,
  IonTabButton,
  IonTabs,
  IonLabel,
  IonIcon,
  IonPage,
  IonRouterOutlet,
} from '@ionic/vue';
import { book, heart, home } from 'ionicons/icons';
import { useI18n } from 'vue-i18n/index';
import auth from '@/plugins/auth';
import i18n from '@/plugins/i18n';
import { changeLocale } from '@/plugins/i18n';

import { useRouter, useRoute } from 'vue-router';
import { computed, onMounted } from 'vue';
import useTracking from "@/composables/useTracking";
import {TRACKING_HOME} from "@/config/constants";

export default {
  name: 'MainTabs',
  components: {
    IonLabel,
    IonTabs,
    IonTabBar,
    IonTabButton,
    IonIcon,
    IonPage,
    IonRouterOutlet,
  },
  setup() {
    const { t } = useI18n();
    const router = useRouter();
    const route = useRoute();

    const { trackThis } = useTracking();

    const tabRoutes = computed(() => {
      return [
        {
          tab: 'home',
          href: '/main/home',
          icon: home,
          selected: false,
        },
        {
          tab: 'following',
          href: '/main/following',
          icon: book,
          selected: false,
        },
      ];
    });

    const tabs = computed(() =>
      tabRoutes.value.map((tab) => {
        tab.selected = tab.href === route.path;
        return tab;
      }),
    );

    const openTab = (href) => {
      if (href) {
        router.push(href);
      }
    };

    const trackHome = (tab) => {
      if (tab === 'home') {
        trackThis(TRACKING_HOME);
      }
    };

    return {
      openTab,
      trackHome,
      tabs,
    };
  },
};
</script>

<style scoped>
ion-tab-button {
  --color: var(--ion-text-color);
  max-width: 60px;
}
</style>
