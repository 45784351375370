<template>
  <div class="is-flex ion-align-items-center greeting-header">
    <IonImg
      src="./assets/medicast-logo.png"
      alt="Medicast logo"
      class="logo-button"
      @click="navigateToHome"
    />
    <h2
      v-if="$route.name === 'Home'"
      class="fw-400 title ml-2">{{greeting}}
    </h2>
  </div>
</template>

<script>
import { computed } from 'vue';
import { useStore } from "vuex";
import { useI18n } from 'vue-i18n/index';
import { useRouter, useRoute } from 'vue-router';
import {
  IonImg,
} from '@ionic/vue';


export default {
  name: "GreetingHeader",
  components: {
    IonImg,
  },
  setup() {
    const store = useStore();
    const { t } = useI18n();
    const router = useRouter();
    const route = useRoute();
    const navigateToHome = () => {
      router.push('/main/home');
    };


    const greeting = computed(() => {
      const userName = store.state.profile.profileDetails.first_name || '';
      const localTime = +new Date().toLocaleTimeString('en-GB', { hour: '2-digit' });
      const textGreeting = localTime > 8 && localTime < 13 ? t('home.goodMorning') : localTime >= 13 && localTime < 18 ? t('home.goodAfternoon') : t('home.goodNight');
      return textGreeting + ' ' +  userName;
    });

    return {
      greeting,
      navigateToHome,
    };

  },
};
</script>

<style scoped>
.greeting-header{
  max-width: 500px;
}
.title {
  color: var(--ion-color-primary);
}
@media (max-width: 576px) {
  .title {
    display: none;
  }
}
.logo-button {
  width: 48px;
  height: 48px;
  cursor: pointer;
}
</style>
